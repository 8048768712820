import * as actions from './customer.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { DocumentReference } from '@angular/fire/firestore';
import { Statement } from '../models/models';

// Main data interface

export interface StoreLocation  {
    name: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zip: string;
}

interface SurveySettings {
  disabled: boolean;
  text: boolean;
  email: boolean;
}

export interface Customer {
    statements?: Statement[];
    id?: string;
    ref?: DocumentReference;
    retailerRef?: DocumentReference;
    name: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    discount?: number;
    salesTax?: number;
    hidePrice?: boolean;
    source?: string;
    client_secret?: string;
    btok?: string;
    ba?: string;
    last4?: string;
    bankName?: string;
    verified?: boolean;
    stripeCustomerId?: string;
    paymentMethod?: string;
    isDeleted?: boolean;
    hasMultipleLocations?: boolean;
    locations?: StoreLocation[];
    surveySettings: SurveySettings;
}

// Entity adapter
export const customerAdapter = createEntityAdapter<Customer>();
export interface State extends EntityState<Customer> { }


export const initialState: State = customerAdapter.getInitialState();

// Reducer

export function customerReducer(
    state: State = initialState,
    action: actions.CustomerActions) {

    switch (action.type) {

        case actions.ADDED:
            return customerAdapter.addOne(action.payload, state);

        case actions.ADDALL:
            return customerAdapter.addMany(action.payload, state);

        case actions.MODIFIED:
            return customerAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state);

        case actions.MODIFIEDALL:
            return customerAdapter.updateMany(action.payload.map(c => ({
                id: c.id,
                changes: c
            })), state);

        case actions.UPDATE:
            return customerAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state);

        case actions.REMOVED:
            return customerAdapter.removeOne(action.payload.id, state);

        case actions.REMOVEDALL:
            return customerAdapter.removeMany(action.payload.map(c => c.id), state);

        default:
            return state;
    }

}

// Create the default selectors

export const getCustomerState = createFeatureSelector<State>('customer');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = customerAdapter.getSelectors(getCustomerState);



