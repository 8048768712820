import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import moment from 'moment';
import { Order } from '../order/order.reducer';
import { Product } from '../products/products.reducer';
import { Staff } from '../staff/staff.reducer';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }

  saveWorkbook(workbook: Workbook, fileName: string) {
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fileName}.xlsx`)
    })
  }

  exportInventoryToExcel(products: any[]) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('VaultWrx Inventory');
    const headerRow = worksheet.addRow(Object.keys(products[0]));
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '59C067' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    worksheet.addRows(products.map(p => Object.values(p)));
    worksheet.columns.forEach(column => {
      column.width = 25;
    });
    this.saveWorkbook(workbook, `VW Inventory - ${moment(new Date).format('YYYY_MM_DD_HHmmss')}`);
  }

  exportOrdersToExcel(orders: Order[], staff: Staff[]) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('VaultWrx');
    const data = this.formatCSVData(orders, staff);
    const headerRow = worksheet.addRow(Object.keys(data[0]));
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '59C067' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    worksheet.addRows(data.map(o => Object.values(o)));
    worksheet.columns.forEach((column) => {
      column.width = 25;
    });
    worksheet.getColumn('Q').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('Q').width = 18;
    worksheet.getColumn('S').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('S').width = 18;
    worksheet.getColumn('T').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('T').width = 18;
    worksheet.getColumn('U').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('U').width = 18;
    worksheet.getColumn('V').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('V').width = 18;
    worksheet.getColumn('W').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('W').width = 18;
    worksheet.getColumn('X').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('X').width = 18;
    worksheet.getColumn('Y').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('Y').width = 18;
    worksheet.getColumn('Z').numFmt = '$#,##0.00;[Red]-$#,##0.00';
    worksheet.getColumn('Z').width = 18;
    this.saveWorkbook(workbook, `VW_${moment(new Date(), 'YYYY_MM_DD_HHmmss').format('YYYY_MM_DD_HHmmss')}`);
  }

  private totalPrices(order: Order) {
    let total = 0
    let productPrice = 0;
    let holidayCharge = 0;
    let saturdayCharge = 0;
    let sundayCharge = 0;
    let platformFee = 0;
    let creditCardFee = 0;
    let onAccountFee = 0;
    let achFee = 0;
    if (!order.name) {
      order.items.forEach(item => total += +item.price * +item.qty);
      productPrice = total;
    } else {
      total = +order.productOptions.price;
      productPrice = total;
      if (order.serviceType) {
        total += +order.serviceType.price;
      }
      if (order.serviceExtras) {
        order.serviceExtras.forEach(extra => total += +extra.price);
      }
    }
    if (order.extraCharges) {
      order.extraCharges.forEach(extra => {
        total += +extra.price;
        switch (extra.name) {
          case 'ACH Fee':
            achFee = +extra.price
            break;
          case 'Credit Card Fee':
            creditCardFee = +extra.price;
            break;
          case 'Holiday':
            holidayCharge = +extra.price;
            break;
          case 'On Account Fee':
            onAccountFee = +extra.price;
            break;
          case 'Platform Fee':
            platformFee = +extra.price;
            break;
          case 'Saturday':
            saturdayCharge = +extra.price;
            break;
          case 'Sunday':
            sundayCharge = +extra.price;
            break;
          default:
            break;
        }
      });
    }
    return {
      productPrice,
      holidayCharge,
      saturdayCharge,
      sundayCharge,
      platformFee,
      creditCardFee,
      onAccountFee,
      achFee,
      total
    };
  }

  private formatCSVData(orders: Order[], staff: Staff[]) {
    return orders.map(o => {
      let payment = 'Paid';
      if (!o.charge) {
        payment = 'Not Paid';
      }
      if (o.customer?.paymentMethod === 'onAccount') {
        payment = 'Billed on account';
      }
      const totals = this.totalPrices(o);
      const contact = staff.find(s => s.id === o.directorRef?.id);
      let name = o.contact;
      let email = o.email;
      let cellPhone = o.cellPhone;
      if (contact) {
        name = contact.name;
        email = contact.email;
        cellPhone = contact.cellPhone;
      }
      return {
        id: o.id,
        funeralHome: o.customer?.name,
        deceased: o.name,
        comments: o.comments,
        contact: name,
        cellPhone: cellPhone,
        email: email,
        location: o.location,
        cemetery: o.cemetery,
        emblem: o.emblem,
        serviceDate: o.displayDate,
        serviceTime: o.displayTimeOfService,
        arrivalTime: o.displayArrivalTime,
        vaultSettler: staff.find(s => s.id === o.staffRef?.id)?.name,
        product: o.name ? o.productOptions.name : o.items.map(i => i.name).toString(),
        paintColor: o.productPaintColorOptions,
        productPrice: totals.productPrice,
        serviceExtras: o.serviceExtras && o.serviceExtras.map(e => e.name).toString(),
        serviceExtrasPrice: o.serviceExtras && o.serviceExtras.map(e => +e.price).reduce((a, b) => a + b, 0),
        holidayCharge: totals.holidayCharge,
        saturdayCharge: totals.saturdayCharge,
        sundayCharge: totals.sundayCharge,
        platformFee: totals.platformFee,
        creditCardFee: totals.creditCardFee,
        achFee: totals.achFee,
        totalPrice: totals.total,
        status: o.status,
        charge: payment
      };
    })
  }
}
