import * as actions from './staff.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { DocumentReference } from '@angular/fire/firestore';

// Main data interface

export interface Staff {
    id?: string;
    ref?: DocumentReference;
    retailerRef?: DocumentReference;
    customerRef?: DocumentReference;
    name: string;
    cellPhone?: string;
    email?: string;
    enableSurvey?: boolean;
    isDeleted?: boolean;
    updates?: {
      subscribed: boolean;
      notification: 'sms' | 'email' | 'both';
    };
}

// Entity adapter
export const staffAdapter = createEntityAdapter<Staff>();
export interface State extends EntityState<Staff> { }


export const initialState: State = staffAdapter.getInitialState();

// Reducer

export function staffReducer(
    state: State = initialState,
    action: actions.StaffActions) {

    switch (action.type) {

        case actions.ADDED:
            return staffAdapter.addOne(action.payload, state);

        case actions.ADDEDALL:
            return staffAdapter.addMany(action.payload, state);

        case actions.MODIFIED:
            return staffAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state);

        case actions.MODIFIEDALL:
            return staffAdapter.updateMany(action.payload.map(s => ({
                id: s.id,
                changes: s
            })), state);

        case actions.UPDATE:
            return staffAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state);

        case actions.REMOVED:
            return staffAdapter.removeOne(action.payload.id, state);

        case actions.REMOVEDALL:
            return staffAdapter.removeMany(action.payload.map(s => s.id), state);
        default:
            return state;
    }

}

// Create the default selectors

export const getStaffState = createFeatureSelector<State>('staff');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = staffAdapter.getSelectors(getStaffState);
