import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import * as updatesActions from './updates.actions';
import {
  AngularFirestore
} from '@angular/fire/firestore';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Updates } from './updates.reducer';

@Injectable()
export class UpdatesEffects {

    @Effect()
    query$: Observable<Action> = this.actions$.pipe(
      ofType(updatesActions.QUERY),
      switchMap(() => {
        return this.afs.collection<Updates>('updates').stateChanges();
      }),
      mergeMap(actions =>  actions),
      map(action => {
        return {
          type: `[updates] ${action.type}`,
          payload: {
            id: action.payload.doc.id,
            ...action.payload.doc.data()
          }
        };
      })
    );

    @Effect() update$: Observable<Action> = this.actions$.pipe(
      ofType(updatesActions.UPDATE),
        map((action: updatesActions.Update) => action),
        switchMap(data => {
            const ref = this.afs.doc<Updates>(`updates/${data.id}`);
            delete data.changes.id;
            return from(ref.set(data.changes, {
              merge: true
            }));
        }),
        map(() => new updatesActions.Success())
    );
    
    @Effect() delete$: Observable<Action> = this.actions$.pipe(
      ofType(updatesActions.DELETE),
        map((action: updatesActions.Delete) => action),
        switchMap(data => {
          const ref = this.afs.doc<Updates>(`updates/${data.id}`);
          return from(ref.delete());
        }),
        map(() => new updatesActions.Success())
    );
    constructor(private actions$: Actions, private afs: AngularFirestore) { }
}
