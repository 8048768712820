import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signupForm: FormGroup;
  detailForm: FormGroup;

  userState;
  constructor(
    public fb: FormBuilder,
    public auth: AuthService,
    private router: Router,
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.userState = this.auth.user$.subscribe(v => {
      if (v && v.roles && (v.roles.admin || v.roles.customer || v.roles.retailer)) {
        this.router.navigate(['landing']);
      }
    });

    // First Step
    this.signupForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
        ]
      ],
      password: ['',
      ],
      region: ['', [
        ]
      ],
    });
  }

  // Using getters will make your code look pretty
  get email() { return this.signupForm.get('email'); }
  get password() { return this.signupForm.get('password'); }

  signin() {
    return this.auth.emailLogin(this.email.value, this.password.value);
  }
}
