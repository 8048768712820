import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Statement } from '../../models/models';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class TableComponent implements OnChanges {
  @Input() statements: Statement[];
  @Input() name: string;
  filterSubject$ = new BehaviorSubject<string>('');
  filteredStatements$: Observable<any>;
  currentPage$ = new BehaviorSubject<number>(1);
  paginator: MatPaginator;
  PAGE_SIZE = 100;

  columns = [
    'date',
    'path'
  ];

  columnsDef = [
    { heading: 'Date', property: 'date' },
    { heading: 'Download', property: 'path' }
  ];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statements?.previousValue !== changes.statements?.currentValue) {
      this.filteredStatements$ = this.filterSubject$.pipe(
        map(filterValue => {
          this.currentPage$.next(1);
          const source = new MatTableDataSource(this.statements);
          source.filter = filterValue;
          source.paginator = this.paginator;
          return source;
        }),
        switchMap(source => {
          return this.currentPage$.pipe(
            map(pageNumber => {
              let idx = (pageNumber - 1) * this.PAGE_SIZE;
              let table = new MatTableDataSource(
                source.filteredData
                .slice(idx, idx + this.PAGE_SIZE)
                .sort((a: any, b: any) => moment(b.date).diff(moment(a.date)))
              );
              return {
                totalStatements: source.filteredData.length,
                statements: table,
                pageNumber
              };
            })
          );
        })
      );
    }
  }
}
