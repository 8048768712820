import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { tap, map, take } from 'rxjs/operators';
import { NotifyService } from '../shared/notify.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private notify: NotifyService) {}

  canActivate(route: ActivatedRouteSnapshot, state): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      tap(user => {
        if (user && !user.acceptedTerms) {
          this.router.navigate(['/terms']);
        }
      }),
      map(user => {
        for (const permissionId of route.data.permissionIds) {
          if (user?.permissionRef.id === permissionId) {
            return !!user && !!user.roles;
          }
        }
        return false;
      }), // <-- map to boolean
      tap(loggedIn => {
        if (!loggedIn) {
          this.auth.signOut();
        }
      })
    );
  }
}
