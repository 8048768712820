import { DocumentReference } from '@angular/fire/firestore';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { ADDED, MODIFIED, REMOVED, DELETE, UPDATE, ProductActions } from './products.actions';


// Main data interface
export interface Product {
  id?: string;
  retailerRef: DocumentReference;
  name: string;
  quantity: number;
  price: number;
  image?: string;
  imageName?: number;
  category: string;
  history: {
    quantityAdded: number;
    updatedAt: Date;
  }[];
  notification?: {
    minimum: number;
    maximum: number;
  }
  isDeleted: boolean;
  createAt: Date;
  updatedAt: Date;
  attachment?: DocumentReference;

  displayDate?: string;
  displayTime?: string;
  displayAttachements?: Product;
}

// Entity adapter
export const productAdapter = createEntityAdapter<Product>();
export interface State extends EntityState<Product> { }


export const initialState: State = productAdapter.getInitialState();

// Reducer

export function productReducer(
    state: State = initialState,
    action: ProductActions) {

    switch (action.type) {

        case ADDED:
            return productAdapter.addOne(action.payload, state);

        case MODIFIED:
            return productAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state);

        case REMOVED:
            return productAdapter.removeOne(action.payload.id, state);

        case DELETE:
            return productAdapter.removeOne(action.id, state);

        case UPDATE:
            return productAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state);

        default:
            return state;
    }

}

// Create the default selectors

export const getRetailerState = createFeatureSelector<State>('product');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = productAdapter.getSelectors(getRetailerState);
