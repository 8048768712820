import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
declare var Stripe;
@Component({
  selector: 'app-stripe-achdebit',
  templateUrl: './stripe-achdebit.component.html',
  styleUrls: ['./stripe-achdebit.component.scss']
})
export class StripeACHDebitComponent implements OnInit {
  @Input() form: FormGroup;
  stripe;
  achErrors;
  constructor(
    public changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.stripe = Stripe(environment.stripePublishableKey);
    this.form.addControl('routing', new FormControl(''));
    this.form.addControl('account', new FormControl(''));
    this.form.addControl('holder', new FormControl(''));
    this.form.addControl('type', new FormControl(''));
  }

  async getSource() {
    console.log('createToken');
    const response = await this.stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: this.form.get('routing').value,
      account_number: this.form.get('account').value,
      account_holder_name: this.form.get('holder').value,
      account_holder_type: this.form.get('type').value,
    });
    // console.log(response);
    if (response.error) {
        this.achErrors = response.error.message;
        this.changeDetectorRef.detectChanges();
        console.log(response.error.message);
        return;
    } else {
      console.log(response.token);
      return response.token;
    }
  }

  isFilled() {
    return this.form.get('routing').value && this.form.get('account').value;
  }
}
