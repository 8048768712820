import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { UpdatesEffects } from "./updates.effects";
import { updatesReducer } from "./updates.reducer";

@NgModule({
  imports: [
    StoreModule.forFeature('updates', updatesReducer),
    EffectsModule.forFeature([UpdatesEffects])
  ]
})
export class UpdatesModule { }
