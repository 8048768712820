export const environment = {
  production: true,
  sendgrid: {
    key: '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'
  },
  domain: 'vaults-online',
  apiKey: 'AIzaSyA2YTzHQjU0YhHB9q1OvCUx3jyCmaoJLwU',
  messagingSenderID: '217531628584',
  appID: '1:217531628584:web:6024dc49f0d23dda',
  stripePublishableKey: 'pk_live_ybrntIJ9y3rFtJLVteQKxg3y0069s53NTy',
  stripeClientId: 'ca_FACvtEriumcTPMuwueKrBpHVgPC5cOI1'
};
