import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { Add, Query, Update } from '../products.actions';
import { Product, selectAll } from '../products.reducer';
import { Query as RetailerQuery } from '../../retailer/retailer.actions';
import { Retailer, selectAll as selectAllRetailers } from '../../retailer/retailer.reducer';
import firebase from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';


interface IProgressBar {
  progress: number;
}

@Component({
  selector: 'app-current',
  templateUrl: './current.component.html',
  styleUrls: ['./current.component.scss']
})
export class CurrentComponent implements OnInit {
  // @ViewChild('upload', { static: false }) upload: UploadPageComponent;
  public form: FormGroup;
  showForm = false;
  productForm: FormGroup;
  imagePreview: any;
  vaultImage: any;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<Product>();
  counter = 1;
  public validationMessages = {
    product: [
      { type: 'required', message: 'Product must be provided' }
    ],
    name: [
      { type: 'required', message: 'Product must be provided' }
    ],
    quantity: [
      { type: 'required', message: 'Quantity must be provided' }
    ],
    min: [
      { type: 'required', message: 'Minimum must be provided' }
    ],
    max: [
      { type: 'required', message: 'Maximum must be provided' }
    ],
    type: [
      { type: 'required', message: 'Please provide at least one notification type' }
    ]
  }
  columnsDef = [
    {heading: 'Category', property: 'category', prefix: ''},
    {heading: 'Name', property: 'name', prefix: ''},
    {heading: 'Price', property: 'price', prefix: '$'},
    {heading: 'quantity', property: 'quantity', prefix: ''},
    {heading: 'retailerRef', property: 'retailerRef', prefix: ''}
  ];

  public products: Product[] = [];
  public columns = ['counter','category', 'name', 'quantity', 'edit'];

  public dataSource$: Observable<Product[]>;
  currentPage$ = new BehaviorSubject(1);
  dataOnPage$: Observable<Product[]>;
  pageSize = 25;
  private productId: string;
  private user: User;
  public options: Product[] = [];
  private retailer: Retailer;
  public isSyncRequired = false;
  selectedFile: File = null;
  downloadUrl: Observable<string>;
  fileUrl: string;
  newImageName: number;
  oldImageName: number;
  progressBar: IProgressBar = { progress: 0 };
  loading = false;


  constructor(
    private auth: AuthService,
    private store: Store,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private excelService: ExportExcelService,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.auth.user$.subscribe(user => {
      if (user.roles.retailer) {
        this.user = user;
        this.store.dispatch(new Query(user));
        this.store.dispatch(new RetailerQuery());
        this.dataOnPage$ = this.currentPage$.pipe(
          switchMap(() => this.store.select(selectAll)),
          map(v => {
            this.store.select(selectAllRetailers).subscribe(res => {
              this.retailer = res.find(r => r.id === user.retailerRef.id);
            });
            const products = Object.values(v).sort((a, b) => {
              if (a.category > b.category) return 1;
              if (a.category < b.category || a.name < b.name) return -1;
              return a.name.localeCompare(b.name);
            });
            this.products = v;
            return products;
          })
        );
        this.changeDetectorRef.detectChanges();
      }
    });
    this.createForm();
  }

  get attachments() {
    return this.form.get('attachments') as FormArray;
  }

  private initProductFields(product: Product) {
    return this.fb.group({
      name: [product, Validators.required],
    });
  }
  createForm() {
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      category: ['', Validators.required],
      price: ['', Validators.required],
      quantity: ['', Validators.required],
      image: ['']
    });
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
  
  onSubmit() {
    const date = new Date();
    this.addProduct({
      name: this.productForm.get('name').value,
      category: this.productForm.get('category').value,
      price: this.productForm.get('price').value,
      quantity: this.productForm.get('quantity').value,
      image: this.imagePreview || null,
      imageName: null,
      retailerRef: this.user.retailerRef,
      history: [],
      isDeleted: false,
      createAt: date,
      updatedAt: date
    });
  }

  onFileSelected(files) {
    if (files.length === 0) {
      return;
    }

    if (files[0].type.match(/image\/*/) == null) {
      return;
    }

    this.selectedFile = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.vaultImage = reader.result;
    }
    const timeStamp = Date.now();
    const filePath = `VaultsImages/${timeStamp}`;
    const fileRef = this.storage.ref(filePath);
    this.loading = true;
    this.storage.upload(filePath, this.selectedFile).snapshotChanges().pipe(
      finalize(() => {
        this.downloadUrl = fileRef.getDownloadURL();
        this.downloadUrl.subscribe(url => {
          if (url) {
            this.fileUrl = url;
            this.newImageName = timeStamp;
            this.loading = false;
          }
        })
      })
    ).subscribe(uploadTaskSnapshot => {
      if(uploadTaskSnapshot){
        this.updateProgress(this.progressBar, (uploadTaskSnapshot.bytesTransferred / uploadTaskSnapshot.totalBytes) * 100);
      }
    })
  }

  updateProgress(progressBar: IProgressBar, value: number) {
    progressBar.progress = value;
  }

  public addItem(product: Product) {
    this.attachments.push(this.initProductFields(product));
  }

  public compareProducts(p1: Product, p2: Product) {
    return p1.id === p2.id;
  }

  public removeItem(index) {
    const selection = this.attachments.controls[index].value.name;
    if (selection.attachment) {
      this.store.dispatch(new Update(selection.id, { attachment: null }, this.user));
    }
    this.attachments.removeAt(index);
  }

  private addProduct(product: Product) {
    this.store.dispatch(new Add(product, this.user));
  }

  public open(content, product: Product) {
    this.productId = product.id;
    this.options = this.products
      .filter(p => p.id !== this.productId)
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort options by name
    this.form = this.fb.group({
      min: [product.notification?.minimum],
      max: [product.notification?.maximum],
      quantity: [product.quantity, Validators.required],
      attachments: this.fb.array([])
    });
    this.options.filter(option => option.attachment?.id === product.id).forEach(option => {
      this.addItem(option);
    });
    this.changeDetectorRef.detectChanges();
    this.modalService.open(content);
  }

  public onUpdate() {
    if (this.form.valid) {
      this.form.get('attachments').value?.forEach(attachment => {
        this.store.dispatch(
          new Update(attachment.name.id, { attachment: firebase.firestore().doc(`products/${this.productId}`) }, this.user));
      });
      this.store.dispatch(new Update(this.productId, {
        quantity: this.form.get('quantity').value,
        notification: {
          minimum: this.form.get('min').value,
          maximum: this.form.get('max').value
        }
      }, this.user));
      this.modalService.dismissAll();
    }
  }

  public onClick() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  public download() {
    this.excelService.exportInventoryToExcel(this.products.map(p => ({
      ID: p.id,
      Name: p.name,
      Price: p.price,
      Category: p.category,
      Quantity: p.quantity,
      Date: p.displayDate,
      Time: p.displayTime
    })));
  }
}
