import { Action } from '@ngrx/store';
import { Customer } from './customer.reducer';
import { User } from '../models/user.model';

export const QUERY    = '[customer] query customers';
export const QUERYWITHRETAILER = '[customer] query customers with retailer'

export const ADDED    = '[customer] added';
export const ADDALL   = '[customer] added all';
export const MODIFIED = '[customer] modified';
export const MODIFIEDALL = '[customer] modified all';
export const REMOVED  = '[customer] removed';
export const REMOVEDALL  = '[customer] removed all';

export const UPDATE   = '[customer] update';
export const STRIPE   = '[customer] linked to stripe';
export const ADD      = '[customer] add';
export const DELETE   = '[customer] delete';
export const SUCCESS  = '[customer] add/update success';

// Initial query
export class Query implements Action {
    readonly type = QUERY;
    constructor(public user: User) {}
  }

export class QueryWithRetailer implements Action {
readonly type = QUERY;
constructor(public retailerId: string) {}
}

// AngularFire2 StateChanges
export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Customer) {}
}

export class AddedAll implements Action {
    readonly type = ADDALL;
    constructor(public payload) { }
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Customer) {}
}

export class ModifiedAll implements Action {
    readonly type = MODIFIEDALL;
    constructor(public payload: Array<Customer>) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Customer) {}
}

export class RemovedAll implements Action {
    readonly type = REMOVEDALL;
    constructor(public payload: Array<Partial<Customer>>) {}
}
// Run a Firestore Update
export class Stripe implements Action {
    readonly type = STRIPE;
    constructor(
        public code: string
      ) { }
}

// Run a Firestore Update
export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: Partial<Customer>,
        public user: User
      ) { }
}

// Run a Firestore Delete
export class Delete implements Action {
    readonly type = DELETE;
    constructor(
        public id: string,
        public user: User
      ) { }
}

// Run a Firestore Add
export class Add implements Action {
    readonly type = ADD;
    constructor(
        public customer: Customer,
        public user: User
      ) { }
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() {}
}

export type CustomerActions =
    Query |
    QueryWithRetailer |
    Added |
    AddedAll |
    Modified |
    ModifiedAll |
    Removed |
    RemovedAll |
    Update |
    Success;
