import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-order-actions',
  template: '<div>It works!</div>'
})
export class OrderActionsComponent implements OnInit {
  orders$: Observable<any>;

  constructor(private route: ActivatedRoute, private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.get('action') === 'confirm') {
        this.confirm(params.get('id'));
      }
      if (params.get('action') === 'deliver') {
        this.deliver(params.get('id'));
      }
    });
  }

  private confirm(orderId) {
    this.afs.doc(`orders/${orderId}`).update({ confirmed: true }).then(() => {
      alert('Order successfully marked as confirmed. You can close this browser tab.');
    }).catch(err => {
      console.log(err);
      alert('Order not found.');
    });
  }

  private deliver(orderId) {
    this.afs.doc(`orders/${orderId}`).update({ delivered: true, confirmed: true }).then(() => {
      alert('Order successfully marked as delivered. You can close this browser tab.');
    }).catch(err => {
      console.log(err);
      alert('Order not found.');
    });
  }
}
