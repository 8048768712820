import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRetailer from '../../retailer/retailer.reducer';
import * as retailerActions from '../../retailer/retailer.actions';
import * as fromCustomer from '../../customer/customer.reducer';
import * as customerActions from '../../customer/customer.actions';
import * as fromOrder from '../../order/order.reducer';
import * as orderActions from '../../order/order.actions';
import { Subscription, Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-retailer',
  templateUrl: './retailer.component.html',
  styleUrls: ['./retailer.component.scss']
})
export class RetailerComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  retailers$: Observable<fromRetailer.Retailer[]>;
  customers$: Observable<fromCustomer.Customer[]>;
  orders$: Observable<fromOrder.Order[]>;

  form: FormGroup;
  saving = false;

  createForm() {
    this.form = this.fb.group({
      retailer: [''],
      startDate: [''],
      endDate: [''],
    });
  }

  resetFields() {
    this.form = this.fb.group({
      retailer: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });
  }

  constructor(
    private fb: FormBuilder,
    private retailerStore: Store<fromRetailer.State> ,
    private customerStore: Store<fromCustomer.State> ,
    private orderStore: Store<fromOrder.State> ,
    ) { }

  ngOnInit() {
    this.createForm();
    this.retailers$ = this.retailerStore.select(fromRetailer.selectAll);
    this.retailerStore.dispatch(  new retailerActions.Query() );
  }

  async onSubmit(value) {
    const subs: Subscription[] = [];
    this.saving = true;
    const retailer = this.form.get('emails').value as fromRetailer.Retailer;
    subs.push(this.customerStore.select(fromCustomer.selectAll).subscribe(customers => {
      subs.push(this.orderStore.select(fromOrder.selectAll).subscribe(orders => {
      }));
      this.orderStore.dispatch(  new orderActions.QueryWithCustomer(undefined) );
    }));
    this.customerStore.dispatch(  new customerActions.QueryWithRetailer(retailer.id) );
  }
}
