import { Pipe, PipeTransform } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Pipe({name: 'formatNgbTime'})
export class FormatNgbTimePipe implements PipeTransform {
  transform(value: NgbTimeStruct): string {
    if (value.hour > 12) {
        return (value.hour - 12) + ':' + formatMinute(value.minute) + ' PM';
    } else if (value.hour === 12) {
        return value.hour + ':' + formatMinute(value.minute) + ' PM';
    } else if (value.hour === 0) {
        return 12 + ':' + formatMinute(value.minute) + ' AM';
    } else {
        return value.hour + ':' + formatMinute(value.minute) + ' AM';
    }
  }
}


function formatMinute(minute) {
    if (minute < 10) {
        return '0' + minute;
    } else {
        return minute;
    }
}
