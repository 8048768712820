import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-retailer-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})

export class RetailerListComponent implements OnInit {

  retailers: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
