import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices.component';
import { CdkTableModule } from '@angular/cdk/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TableComponent } from './table/table.component';



@NgModule({
  declarations: [InvoicesComponent, TableComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule
  ],
  exports: [
    InvoicesComponent
  ]
})
export class InvoicesModule { }
