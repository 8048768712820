import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
declare var Stripe;
@Component({
  selector: 'app-stripe-credit-card',
  templateUrl: './stripeCreditCard.component.html',
  styleUrls: ['./stripeCreditCard.component.scss']
})
export class StripeCreditCardComponent implements OnInit, OnDestroy {

  @ViewChild('cardElement', { static: true }) cardElement: ElementRef;
  stripe;
  card;
  cardHandler = this.onChange.bind(this);
  cardErrors;

  constructor(public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.stripe = Stripe('pk_live_ybrntIJ9y3rFtJLVteQKxg3y0069s53NTy');
    const elements = this.stripe.elements();

    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.cardErrors = error.message;
    } else {
      this.cardErrors = null;
    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
  async getSource() {
    this.card.usage = 'reusable';
    const { source, error } = await this.stripe.createSource(this.card);

    if (error) {
      // Inform the customer that there was an error.
      if (error.message) {
        this.cardErrors = error.message;
      } else {
        this.cardErrors = 'Error with card';
      }
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
      return;
    } else {
      this.card.clear();
      return source;
    }
  }

  isFilled() {
    return !this.card._empty;
  }

}
