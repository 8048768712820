import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as actions from '../retailer.actions';
import * as fromRetailer from '../retailer.reducer';
import { Retailer } from '../retailer.reducer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { Permission, User } from 'src/app/models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { logAction } from 'src/app/shared/functions';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NotifyService } from 'src/app/shared/notify.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  columns = [
    'name',
    'new-user',
    'statements',
    'edit',
    'delete'
  ];

  columnsDef = [
    {heading: 'Name', property: 'name'}
  ];

  // Private data source
  heroes$: Observable<fromRetailer.Retailer[]>;

  // Public Observable for table
  dataSource$: Observable<any[]>;

  minMax$: Observable<any>;
  selectedRetailers : { id: string, name: string }[] = [];
  attachedRetailers : { id: string, name: any }[] = [];
  items: { showDropdown: boolean }[] = [{ showDropdown: false }];

  // Pagination data
  currentPage$ = new BehaviorSubject(1);
  dataOnPage$: Observable<any[]>;
  showDropdown = false;

  pageSize = 25;
  private user: User;
  permissions: Permission[];
  saving = false;
  form: FormGroup;
  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' }
    ],
    displayName: [
      { type: 'required', message: 'User name is required.' }
    ],
    permission: [
      { type: 'required', message: 'Please select a permission from the dropdown' }
    ]
  };


  constructor(
    private store: Store<fromRetailer.State>,
    private modalService: NgbModal,
    private auth: AuthService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private notify: NotifyService
    ) {
      this.form = this.fb.group({});
    }

  ngOnInit() {
    this.createForm();
    this.heroes$ = this.store.select(fromRetailer.selectAll);
    this.dataSource$ = this.heroes$.pipe(map(v => Object.values(v)));
    this.store.dispatch(  new actions.Query() );
    const data$ = combineLatest([this.auth.user$, this.auth.permissions$]);
    this.subscriptions.push(
      data$.subscribe(([user, permissions]) => {
        this.user = user;
        this.permissions = permissions;
      })
    )
    // Sliced data for pagination
    this.dataOnPage$ = this.currentPage$.pipe(
      switchMap(() => this.heroes$),
      map(v => {
        // Sort the data 
        const sortedData = Object.values(v).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
    
        const idx = (this.currentPage$.value - 1) * this.pageSize;
        return sortedData.slice(idx, idx + this.pageSize);
      })
    );

    this.retailers();
    this. listedRetailer();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public delete(retailer: Retailer) {
    this.modalService.dismissAll();
    this.store.dispatch( new actions.Delete(retailer.id, this.user) );
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result;
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      displayName: ['', Validators.required],
      password: ['', Validators.required],
      permission: ['', Validators.required]
    });
  }

  resetFields() {
    this.createForm();
  }

  get permission(): Permission {
    return this.form.get('permission').value
  }

  retailers() {
    this.auth.user$.subscribe(user => {
      if (user.roles?.retailer) {
        const retailerRef = user.retailerRef.id;
        const userID = user.uid;

        const userRetailersCollection = this.afs.collection('users-retailers');
        userRetailersCollection.valueChanges().subscribe((data: any[]) => {
          const linkedUserAccounts = data.filter(item => item?.UserID?.id === userID);

          const retailerRefs = linkedUserAccounts.map(account => account.retailerRef.id);
          const retailersCollection = this.afs.collection('retailers');
          retailersCollection.snapshotChanges().subscribe(snapshot => {
            const selectedRetailer: { id: string, name: string }[] = [];
            snapshot.forEach(doc => {
              const id = doc.payload.doc.id;
              if (retailerRefs.includes(id)) {
                const retailerName = (doc.payload.doc.data() as { name: string }).name;
                selectedRetailer.push({ id, name: retailerName });
              }
            });
            // Update the 'retailers' array with the selected retailers
            this.selectedRetailers = selectedRetailer;
          });
        });
      }
    });
  }

  listedRetailer() {
    const retailers = this.afs.collection('retailers');
    retailers.snapshotChanges().subscribe((snapshot) => {
      const retailerData = snapshot.map((doc) => {
        const id = doc.payload.doc.id;
        const name = (doc.payload.doc.data() as { name: string }).name;
        return { id, name };
      });
      // Extracting the names and document IDs of retailers from the retailerData array
      this.attachedRetailers = retailerData.map((retailer) => ({
        id: retailer.id,
        name: retailer.name 
      }));

    });
  }
  toggleDropdown(item: { showDropdown: boolean }): void {
    if (!item.showDropdown) {
      item.showDropdown = true;
      this.items.push({ showDropdown: false });
    } else {
      this.items = this.items.filter((i) => i !== item);
    }
  }

  onRetailerSelectionChange(retailerId: string): void {
    // Find the selected retailer object from attachedRetailers based on the retailerId
    const selectedRetailer = this.attachedRetailers.find(retailer => retailer.id === retailerId);
    if (selectedRetailer) {
      // Check if the retailer is not already in the selectedRetailers array
      if (!this.selectedRetailers.find(retailer => retailer.id === retailerId)) {
        this.selectedRetailers.push(selectedRetailer);
      }
    }
    this.viewSelectedRetailersInLogs();
  }

  viewSelectedRetailersInLogs(): void {
    const selectedRetailerIds = this.selectedRetailers.map(retailer => retailer.id)
  }

  async addUser(retailer: Retailer){
    console.log('action started');
    this.auth.user$.subscribe(user => {
      if (user.roles?.retailer || user.roles?.admin) {
        const retailerRef = user.retailerRef?.id
        this.saving = true;
        this.changeDetectorRef.detectChanges();
        const register = this.fns.httpsCallable('register');
        const body: any = {
          emails: this.form.get('email').value,
          roles: {
            admin: false,
            customer: false,
            retailer: true,
          },
          password: this.form.get('password').value,
          retailerId: retailer.id,
          permissionId: this.permission.id,
          user: this.form.get('displayName').value,
          attachedRetailers: this.selectedRetailers.map(retler => retler.id)
        };

        const response$ = register(body);
        console.log('response', response$)
        response$.subscribe(
          async result => {
            if (result) {
              this.resetFields();
              this.modalService.dismissAll();
              const uid = result.users[0].createdUser.uid;
              const retailerFer = result.users[0].retailerId;
              const attachedments = [
                ...body.attachedRetailers,
                retailerFer
              ]
              const attachmentReferences = attachedments.map((retailerId: string) => {
                return this.afs.doc(`retailers/${retailerId}`).ref;
              });
             // Create a new document in the 'users-retailers' collection
            try {
              await this.afs.collection('users-retailers').add({
                UserID: this.afs.doc(`users/${uid}`).ref, // Passing the user reference using 'UserID' field
                attachedRetailers: attachmentReferences, // Passing the attachedRetailers data as an object
              });
              } catch (error) {
                console.error('Error ', error);
              }
              console.log('User has been added successfully!', 'Success');
            }
            this.saving = false;
            logAction({
              action: 'User added',
              user: this.user,
              retailerId: retailer.id
            }, this.afs);
            this.changeDetectorRef.detectChanges();
          },
          error => {
            this.modalService.dismissAll();
            this.notify.update(error.message, 'danger');
            this.saving = false;
            this.changeDetectorRef.detectChanges();
          }
        );
      }
    });
  };
}