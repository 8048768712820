import { Action } from '@ngrx/store';
import { Admin } from './admin.reducer';

export const QUERY = '[admin] query admins';

export const ADDALL = '[admin] add all';
export const ADDED = '[admin] added all';
export const MODIFIED = '[admin] modified';
export const MODIFIEDALL = '[admin] modified all';
export const REMOVED  = '[admin] removed';
export const REMOVEDALL  = '[admin] removed all';
export const UPDATE   = '[admin] update';


export class Query implements Action {
    readonly type = QUERY;
    public constructor() { }
}

// AngularFire2 StateChanges
export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Admin) { }
}

export class AddAll implements Action {
    readonly type = ADDALL;
    constructor(public payload) { }
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Admin) {}
}

export class ModifiedAll implements Action {
    readonly type = MODIFIEDALL;
    constructor(public payload: Array<Admin>) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Admin) {}
}

export class RemovedAll implements Action {
    readonly type = REMOVEDALL;
    constructor(public payload: Array<Partial<Admin>>) {}
}

// Run a Firestore Update
export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: Partial<Admin>,
      ) { }
}

export type AdminActions =
    Query |
    Added |
    AddAll |
    Modified |
    ModifiedAll |
    Removed |
    RemovedAll |
    Update;
