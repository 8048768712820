import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadPageComponent } from './upload-page/upload-page.component';
import { DropZoneDirective } from './drop-zone.directive';
import { FileSizePipe } from './file-size.pipe';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    UploadPageComponent,
    UploadDialogComponent
  ],
  declarations: [
    UploadPageComponent,
    UploadDialogComponent,
    DropZoneDirective,
    FileSizePipe
  ]
})
export class UploadsModule { }
