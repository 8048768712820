import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AdminEffects } from "./admin.effects";
import { adminReducer } from "./admin.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('admin', adminReducer),
        EffectsModule.forFeature([AdminEffects])
    ]
})
export class AdminModule { }
