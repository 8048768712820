import * as actions from './order.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { DocumentReference } from '@angular/fire/firestore';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Customer, StoreLocation } from '../customer/customer.reducer';
import { Survey } from '../surveys/surveys.reducer';

export type OptionTypes = 'Holiday' | 'Saturday' | 'Sunday' | 'Platform Fee' | 'On Account Fee' | 'ACH Fee' | 'Credit Card Fee';

// Main data interface
export interface Option {
    deliveredQuantity: number;
    name: OptionTypes;
    price: number;
    image?: string;
    category?: string;
    qty?: number;
    quantity?: number;
}
export interface Order {
  orderDStatus: string;
  applyPlatformFee: boolean;
  arrivalTime?: NgbTimeStruct;
  birthDate?: NgbDateStruct;
  cellPhone?: string;
  cemetery?: string;
  charge?: any;
  contact?: string;
  comments?: string;
  confirmed?: boolean;
  createdAt?: Date;
  customer?: Customer;
  customerRef?: DocumentReference;
  dateOfService: NgbDateStruct;
  delivered?: boolean;
  deathDate?: NgbDateStruct;
  deliveryInstructions?: string;
  directorRef?: DocumentReference;
  discount?: number;
  subtotal?: number;
  salesTax?: number;
  email?: string;
  emblem?: string;
  extraCharges?: Option[];
  id?: string;
  isDeleted?: boolean;
  isEdited?: boolean;
  isParent?: boolean;
  items?: Option[];
  location?: string;
  name?: string;
  oldVersionRef?: DocumentReference;
  productOptions?: Option;
  productPaintColorOptions?: string;
  rating?: number;
  retailerRef?: DocumentReference;
  serviceExtras?: Option[];
  serviceType?: Option;
  staffRef?: DocumentReference;
  storeLocation?: StoreLocation;
  survey?: Survey;
  timeOfService?: NgbTimeStruct;
  updatedAt?: Date;
  image?: string;
  photo?: string[];
   // Added for filter
  customerName?: string;
  displayArrivalTime?: string;
  displayCharge?: string;
  displayColorRef?: DocumentReference;
  displayDate?: string;
  displayProduct?: string;
  displayTimeOfService?: string;
  status?: string;
}

// Entity adapter
export const orderAdapter = createEntityAdapter<Order>();
export interface State extends EntityState<Order> { }


export const initialState: State = orderAdapter.getInitialState();

// Reducer

export function orderReducer(
    state: State = initialState,
    action: actions.OrderActions) {
    switch (action.type) {
        case actions.ADDED:
            return orderAdapter.addOne(action.payload, state);
        case actions.ADDALL:
            return orderAdapter.addMany(action.payload, state);
        case actions.MODIFIED:
            return orderAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state);
        case actions.MODIFIEDALL:
            return orderAdapter.updateMany(action.payload.map(o => ({
                id: o.id,
                changes: o
            })), state);
        case actions.PARTIALUPDATE:
            return orderAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state);
        case actions.UPDATE:
            return orderAdapter.updateOne({
                id: action.id,
                changes: action.order
            }, state);
        case actions.REMOVED:
            return orderAdapter.removeOne(action.payload.id, state);
        case actions.REMOVEDALL:
            return orderAdapter.removeMany(action.payload.map(o => o.id), state);
        case actions.DELETE:
            return orderAdapter.removeOne(action.id, state);
        default:
            return state;
    }
}

// Create the default selectors

export const getOrderState = createFeatureSelector<State>('order');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = orderAdapter.getSelectors(getOrderState);



