import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';

import { Customer } from './customer.reducer';
import * as customerActions from './customer.actions';

import {
  AngularFirestore
} from '@angular/fire/firestore';

import { switchMap, map, filter } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Retailer } from '../retailer/retailer.reducer';
import { ADDALL, REMOVEDALL, MODIFIEDALL } from './customer.actions';
import { logAction } from '../shared/functions';

@Injectable()
export class CustomerEffects {
  @Effect()
  queryWithRetailer$: Observable<Action> = this.actions$.pipe(
    ofType(customerActions.QUERYWITHRETAILER),
    switchMap((data: customerActions.QueryWithRetailer) => {
      return this.afs.collection<Customer>('customers', ref =>  {
        const retailerRef = this.afs.doc<Retailer>(`retailers/${data.retailerId}`).ref;
        return ref.where('retailerRef', '==', retailerRef);
      }).stateChanges();
    }),
    map((actions) => {
      let changes = [];
      if (actions) {
        const addActions = actions.filter((val: any) => val.type === 'added');
        const removeActions = actions.filter((val: any) => val.type === 'removed');
        const modifyActions = actions.filter((val: any) => val.type === 'modified');
        if (addActions.length > 0) {
          changes.push( {
            type: ADDALL,
            payload: addActions.map((item: any) => {
              const customer = item.payload.doc.data() as Customer;
              return {
                id: item.payload.doc.id,
                ref: item.payload.doc.ref,
                ...customer
              }
            })
          })
        }
        if (removeActions.length > 0) {
          changes.push({
            type: REMOVEDALL,
            payload: removeActions.map(
              (item: any) => ({
                id: item.payload.doc.id
              }))
          })
        }
        if (modifyActions.length > 0) {
          changes.push( {
            type: MODIFIEDALL,
            payload: addActions.map((item: any) => {
              const customer = item.payload.doc.data() as Customer;
              return {
                id: item.payload.doc.id,
                ref: item.payload.doc.ref,
                ...customer
              }
            })
          })
        }
      }
      return changes;
    }),
    filter(customers => customers.length > 0),
    switchMap(customers => from(customers)),
  );

    @Effect()
    query$: Observable<Action> = this.actions$.pipe(
      ofType(customerActions.QUERY),
      map((action: customerActions.Query) => action),
      switchMap(data => {
        return this.afs.collection<Customer>('customers', ref =>  {
          if (data.user && data.user.retailerRef) {
            return ref.where('retailerRef', '==', data.user.retailerRef).where('isDeleted', '==', false);
          }
          return ref;
        }).stateChanges();
      }),
      map((actions) => {
        let changes = [];
        if (actions) {
          const addActions = actions.filter((val: any) => val.type === 'added');
          const removeActions = actions.filter((val: any) => val.type === 'removed');
          const modifyActions = actions.filter((val: any) => val.type === 'modified');
          if (addActions.length > 0) {
            changes.push( {
              type: ADDALL,
              payload: addActions.map((item: any) => {
                const customer = item.payload.doc.data() as Customer;
                return {
                  id: item.payload.doc.id,
                  ref: item.payload.doc.ref,
                  ...customer
                }
              })
            })
          }
          if (removeActions.length > 0) {
            changes.push({
              type: REMOVEDALL,
              payload: removeActions.map(
                (item: any) => ({
                  id: item.payload.doc.id
                }))
            })
          }
          if (modifyActions.length > 0) {
            changes.push( {
              type: MODIFIEDALL,
              payload: addActions.map((item: any) => {
                const customer = item.payload.doc.data() as Customer;
                return {
                  id: item.payload.doc.id,
                  ref: item.payload.doc.ref,
                  ...customer
                }
              })
            })
          }
        }
        return changes;
      }),
      filter(customers => customers.length > 0),
      switchMap(customers => from(customers)),
    );

    @Effect() update$: Observable<Action> = this.actions$.pipe(
      ofType(customerActions.UPDATE),
        map((action: customerActions.Update) => action),
        switchMap(data => {
            const ref = this.afs.doc<Customer>(`customers/${data.id}`);
            logAction({
              action: 'Customer updated',
              user: data.user,
              customerId: data.id
            }, this.afs);
            delete data.changes.id;
            delete data.changes.ref;
            return from( ref.update(data.changes) );
        }),
        map(() => new customerActions.Success())
    );

    @Effect() add$: Observable<Action> = this.actions$.pipe(
      ofType(customerActions.ADD),
        map((action: customerActions.Add) => action),
        switchMap(data => {
            const col = this.afs.collection<Customer>('customers');
            delete data.customer.id;
            delete data.customer.ref;
            col.add(data.customer).then(ref => {
              logAction({
                action: 'Customer added',
                user: data.user,
                customerId: ref.id
              }, this.afs);
            });
            return from([]);
        }),
        map(() => new customerActions.Success())
    );

    @Effect() delete$: Observable<Action> = this.actions$.pipe(
      ofType(customerActions.DELETE),
        map((action: customerActions.Delete) => action),
        switchMap(data => {
            const doc = this.afs.doc<Customer>(`customers/${data.id}`);
            data.user.retailerRef.get().then(snapshot => {
              const invoices = (snapshot.data() as Retailer).invoices;
              const slicedInvoices = invoices.map(invoice => {
                if (invoice.customerRef.id !== doc.ref.id) {
                  return invoice;
                }
              }).filter(i => i);
              data.user.retailerRef.update({ invoices: slicedInvoices }).catch(err => console.log(err));
            }).catch(err => console.log(err));
            logAction({
              action: 'Customer deleted',
              user: data.user,
              customerId: data.id
            }, this.afs);
            return from(doc.update({ isDeleted: true }));
        }),
        map(() => new customerActions.Success())
    );

    constructor(private actions$: Actions, private afs: AngularFirestore) { }
}
