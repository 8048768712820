import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices.component';
import { CdkTableModule } from '@angular/cdk/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TableComponent } from './table/table.component';
import { ReviewChargesComponent } from './reviews/review-charges.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [InvoicesComponent, ReviewChargesComponent, TableComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    MatTableModule,
    NgbModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule
  ],
  exports: [
    InvoicesComponent,
    ReviewChargesComponent
  ]
})
export class InvoicesModule { }
