import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { NewComponent } from './new/new.component';
import { CurrentComponent } from './current/current.component';
import { ProductRoutingModule } from './products.routing.module';
import { StoreModule } from '@ngrx/store';
import { productReducer } from './products.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductEffects } from './products.effects';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PastComponent } from './past/past.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    CurrentComponent,
    NewComponent,
    PastComponent
  ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    StoreModule.forFeature('product', productReducer),
    EffectsModule.forFeature([ProductEffects]),
    CdkTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    NgbPaginationModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule
  ]
})
export class ProductsModule { }
