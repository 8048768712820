import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Statement } from '../../models/models';
import { Router } from '@angular/router';
import { MY_DATE_FORMATS, STATEMENT_DATE_FORMAT } from 'src/app/utils';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ]
})
export class TableComponent implements OnInit, OnChanges {
  @Input() invoices: Statement[];
  @Input() date: string;
  dateControl = new FormControl();
  filterSubject$ = new BehaviorSubject<string>('');
  filteredInvoices$: Observable<{
    totalInvoices: number,
    pageNumber: number,
    invoices: MatTableDataSource<Statement>
  }>;
  currentPage$ = new BehaviorSubject<number>(1);
  paginator: MatPaginator;
  PAGE_SIZE = 100;
  @Output() updateSelectedInvoices: EventEmitter<{ invoices: Statement[] }> = new EventEmitter();

  columns = [
    'name',
    'location',
    'date',
    'path'
  ];

  columnsDef = [
    { heading: 'Customer', property: 'name' },
    { heading: 'Location', property: 'location' },
    { heading: 'Date', property: 'date' },
    { heading: 'Download', property: 'path' }
  ];

  dataSource = new MatTableDataSource<Statement>();
  constructor(private router: Router) { }

  ngOnInit() {
    this.dateControl.setValue(moment(this.date, STATEMENT_DATE_FORMAT));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoices?.previousValue !== changes.invoices?.currentValue) {
      this.filteredInvoices$ = this.filterSubject$.pipe(
        map(filterValue => {
          this.currentPage$.next(1);
          this.dataSource.data = this.invoices;
          this.dataSource.filter = filterValue;
          this.dataSource.paginator = this.paginator;
          return this.dataSource;
        }),
        switchMap(source => {
          return this.currentPage$.pipe(
            map(pageNumber => {
              let idx = (pageNumber - 1) * this.PAGE_SIZE;
              let table = new MatTableDataSource(source.filteredData.slice(idx, idx + this.PAGE_SIZE));
              this.updateSelectedInvoices.emit({ invoices: table.data });
              return {
                totalInvoices: source.filteredData.length,
                invoices: table,
                pageNumber
              };
            })
          );
        })
      );
    }
  }

  public chosenYearHandler(normalizedYear: moment.Moment) {
    const controlValue = this.dateControl.value;
    controlValue.year(normalizedYear.year());
    this.dateControl.setValue(controlValue);
  }

  public chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const controlValue = this.dateControl.value;
    controlValue.month(normalizedMonth.month());
    this.dateControl.setValue(controlValue);
    this.filterSubject$.next(this.dateControl.value.format("MMMM YYYY"));
    datepicker.close();
    this.router.navigate([window.location.pathname], { queryParams: {
      filter: moment(this.dateControl.value).format('MMMM YYYY')
    }})
  }
}
