import { Action } from '@ngrx/store';
import { Order } from './order.reducer';
import { User } from '../models/user.model';
import { AppOrderActions } from '../models/models';

export const QUERY = '[order] query orders';
export const ADDED = '[order] added';
export const ADDALL = '[orders] added all';
export const MODIFIED = '[order] modified';
export const MODIFIEDALL = '[order] modified all';
export const REMOVED = '[order] removed';
export const REMOVEDALL = '[order] removed all';

export const PARTIALUPDATE = '[order] partial update';
export const UPDATE = '[order] update';
export const CONFIRM = '[order] confirmed';
export const ONGOING = '[order] ongoing';
export const DELIVER = '[order] delivered';
export const REVERTEDIT = '[order] revertd';
export const ADD = '[order] add';
export const DELETE = '[order] delete';
export const SUCCESS = '[order] add/update success';

// Initial query with date
export class Query implements Action {
    readonly type = QUERY;
    constructor(public user: User, public date?: Date) { }
}
export class QueryWithCustomer implements Action {
    readonly type = QUERY;
    constructor(public customerId: string) { }
}
// AngularFire2 StateChanges
export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Order) { }
}

// AngularFire2 StateChanges
export class AddedAll implements Action {
    readonly type = ADDALL;
    constructor(public payload) { }
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Order) { }
}

export class ModifiedAll implements Action {
    readonly type = MODIFIEDALL;
    constructor(public payload: Array<Order>) { }
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Order) { }
}

export class RemovedAll implements Action {
    readonly type = REMOVEDALL;
    constructor(public payload: Array<Partial<Order>>) { }
}

// Run a Firestore Update
export class PartialUpdate implements Action {
    readonly type = PARTIALUPDATE;
    constructor(
        public id: string,
        public changes: Partial<Order>,
        public action: AppOrderActions,
        public user: User
    ) { }
}

// Run a Firestore Update
export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public order: Order,
        public user: User
    ) { }
}

// Run a Firestore Delete
export class Delete implements Action {
    readonly type = DELETE;
    constructor(
        public id: string,
        public user: User
    ) { }
}

// Run a Firestore Update
export class Confirm implements Action {
    readonly type = CONFIRM;
    constructor(
        public id: string,
        public user: User
    ) { }
}

// Run a Firestore Update
export class Ongoing implements Action {
    readonly type = ONGOING;
    constructor(
        public id: string,
        public user: User
    ) { }
}

// Run a Firestore Update
export class Deliver implements Action {
    readonly type = DELIVER;
    constructor(
        public id: string,
        public user: User
    ) { }
}

export class RevertEdit implements Action {
    readonly type = REVERTEDIT;
    constructor(public order: Order, public user: User){ }
}

// Run a Firestore Add
export class Add implements Action {
    readonly type = ADD;
    constructor(
        public order: Order,
        public user: User
    ) { }
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() { }
}

export type OrderActions =
    Query |
    QueryWithCustomer |
    Added |
    AddedAll |
    Modified |
    ModifiedAll |
    RemovedAll |
    Removed |
    Delete |
    PartialUpdate |
    Update |
    Success;
