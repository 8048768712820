import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { Query } from '../products.actions';
import { Product, selectAll } from '../products.reducer';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import moment from 'moment';

@Component({
  selector: 'app-past',
  templateUrl: './past.component.html',
  styleUrls: ['./past.component.scss']
})
export class PastComponent implements OnInit ,AfterViewInit{
  public columns = ['counter','name', 'quantity', 'date', 'time'];
  public dataSource$: Observable<Product[]>;
  currentPage$ = new BehaviorSubject(1);
  dataOnPage$: Observable<Product[]>;
  pageSize = 25;
  public products: Product[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Product>();
  counter = 1;
  constructor(
    private auth: AuthService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private excelService: ExportExcelService,
    private changeDetectorRef: ChangeDetectorRef
  ){}


ngOnInit() {
  this.auth.user$.subscribe(user => {
    if (user.roles.retailer) {
      this.store.dispatch(new Query(user));

      this.currentPage$.pipe(
        switchMap(() => this.store.select(selectAll)),
        map(v => {
          // Filter products with non-empty history
          const filteredProducts = v.filter(product => product.history.length > 0);

          const idx = (this.currentPage$.value - 1) * this.pageSize;
          const endIndex = Math.min(idx + this.pageSize, filteredProducts.length);
          const products = filteredProducts.slice(idx, endIndex);

          const history = products.reduce((acc, product, i) => {
            product.history.forEach((h, j) => {
              const updatedAt = h.updatedAt as unknown as firebase.default.firestore.Timestamp;
              acc.push({
                id: `${i}~${j}`,
                name: product.name,
                quantity: h.quantityAdded,
                date: moment(updatedAt.seconds * 1000).format('MM/DD/YYYY'),
                time: moment(updatedAt.seconds * 1000).format('hh:mm A')
              });
            });
            return acc;
          }, []);

          history.sort((a, b) => {
            const dateA = moment(a.date, 'MM/DD/YYYY');
            const dateB = moment(b.date, 'MM/DD/YYYY');
            return dateB.diff(dateA);
          });

          this.dataSource.data = history;
          this.paginator.length = filteredProducts.length;

          return history;
        })
      ).subscribe(data => {
        // Handle data as needed
      });

      this.changeDetectorRef.detectChanges();
    }
  });
}


ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;

  // Listen to the page change event and update the currentPage$ accordingly
  this.paginator.page.subscribe((event) => {
    this.currentPage$.next(event.pageIndex + 1);
  });
}



  public onClick() {
    this.router.navigate(['..', 'new'], { relativeTo: this.route });
  }

  public download() {
    this.excelService.exportInventoryToExcel(this.products.map(p => ({
      ID: p.id,
      Name: p.name,
      Quantity: p.quantity,
      Date: p.displayDate,
      Time: p.displayTime
    })));
  }
}
