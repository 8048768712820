import { Action } from '@ngrx/store';
import { Staff } from './staff.reducer';
import { User } from '../models/user.model';

export const QUERY    = '[staff] query staff';

export const ADDED    = '[staff] added';
export const ADDEDALL    = '[staff] added all';
export const MODIFIED = '[staff] modified';
export const MODIFIEDALL = '[staff] modified all';
export const REMOVED  = '[staff] removed';
export const REMOVEDALL  = '[staff] removed all';

export const UPDATE   = '[staff] update';
export const STRIPE   = '[staff] linked to stripe';
export const ADD      = '[staff] add';
export const DELETE   = '[staff] delete';
export const SUCCESS  = '[staff] add/update success';

// Initial query
export class Query implements Action {
    readonly type = QUERY;
    constructor(public user: User, public customerStaff: boolean = false) {}
  }

// AngularFire2 StateChanges
export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Staff) {}
}

export class AddedALL implements Action {
    readonly type = ADDEDALL;
    constructor(public payload: Array<Staff>) {}
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Staff) {}
}

export class ModifiedAll implements Action {
    readonly type = MODIFIEDALL;
    constructor(public payload: Array<Staff>) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Staff) {}
}

export class RemovedAll implements Action {
    readonly type = REMOVEDALL;
    constructor(public payload: Array<Partial<Staff>>) {}
}
// Run a Firestore Update
export class Stripe implements Action {
    readonly type = STRIPE;
    constructor(
        public code: string
      ) { }
}

// Run a Firestore Update
export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: Partial<Staff>,
      ) { }
}

// Run a Firestore Delete
export class Delete implements Action {
    readonly type = DELETE;
    constructor(
        public id: string
      ) { }
}

// Run a Firestore Add
export class Add implements Action {
    readonly type = ADD;
    constructor(
        public staff: Staff,
      ) { }
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() {}
}

export type StaffActions =
    Query |
    Added |
    AddedALL |
    Modified |
    ModifiedAll |
    Removed |
    RemovedAll |
    Update |
    Success;
