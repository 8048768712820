import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../notify.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent implements OnInit {
  constructor(public notify: NotifyService) { }

  ngOnInit() {
    this.notify.msg.pipe(
      debounceTime(8000)
    ).subscribe(() => this.notify.clear());
  }
}
