import { Action } from '@ngrx/store';
import { User } from '../models/user.model';
import { Product } from './products.reducer';

export const QUERY    = '[product] query products';

export const ADDED    = '[product] added';
export const MODIFIED = '[product] modified';
export const REMOVED  = '[product] removed';

export const UPDATE   = '[product] update';
export const ADD      = '[product] add';
export const DELETE   = '[product] delete';
export const SUCCESS  = '[product] add/update success';

// Initial query
export class Query implements Action {
    readonly type = QUERY;
    constructor(public user: User) {}
  }

// AngularFire2 StateChanges
export class Added implements Action {
  readonly type = ADDED;
  constructor(public payload: Product) {}
}

export class Modified implements Action {
  readonly type = MODIFIED;
  constructor(public payload: Product) {}
}

export class Removed implements Action {
  readonly type = REMOVED;
  constructor(public payload: Product) {}
}

// Run a Firestore Update
export class Update implements Action {
  readonly type = UPDATE;
  constructor(
    public id: string,
    public changes: Partial<Product>,
    public user: User
  ) { }
}

// Run a Firestore Delete
export class Delete implements Action {
  readonly type = DELETE;
  constructor(
    public id: string,
    public user: User
  ) { }
}

// Run a Firestore Add
export class Add implements Action {
  readonly type = ADD;
  constructor(
    public product: Product,
    public user: User
  ) { }
}

export class Success implements Action {
  readonly type = SUCCESS;
  constructor() {}
}

export type ProductActions =
  Query |
  Added |
  Modified |
  Removed |
  Delete |
  Update |
  Success;
