import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { CurrentComponent } from './current/current.component';
import { NewComponent } from './new/new.component';
import { PastComponent } from './past/past.component';
const routes: Routes = [
  {
    path: '',
    component: CurrentComponent,
    canActivate: [AuthGuard],
    data: {
      permissionIds: ['READ_WRITE', 'LIMITED']
    }
  },
  {
    path: 'new',
    component: NewComponent,
    canActivate: [AuthGuard],
    data: {
      permissionIds: ['READ_WRITE', 'LIMITED']
    }
  },
  {
    path: 'past',
    component: PastComponent,
    canActivate: [AuthGuard],
    data: {
      permissionIds: ['READ_WRITE', 'LIMITED']
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
