import { NgModule } from '@angular/core';
import { FormatNgbTimePipe } from './pipes/formatNgbTime.pipe';
import { CommonModule } from '@angular/common';
import { StripeCreditCardComponent } from './stripeCreditCard/stripeCreditCard.component';
import { StripeACHDebitComponent } from './stripe-achdebit/stripe-achdebit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { StripePaymentChoiceComponent } from './stripe-payment-choice/stripe-payment-choice.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from './notify.service';
import { NotificationMessageComponent } from './notification-message/notification-message.component';

@NgModule({
  providers: [NotifyService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    MatButtonModule,
    MatInputModule,
    MatSliderModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    NgbModule,
  ],
  exports: [
    FormatNgbTimePipe,
    StripeCreditCardComponent,
    StripeACHDebitComponent,
    StripePaymentChoiceComponent,
    NotificationMessageComponent
  ],
  declarations: [
    FormatNgbTimePipe,
    StripeCreditCardComponent,
    StripeACHDebitComponent,
    StripePaymentChoiceComponent,
    NotificationMessageComponent
  ]
})
export class SharedModule { }
