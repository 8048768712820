import { DocumentReference } from "@angular/fire/firestore";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector } from "@ngrx/store";
import * as actions from './surveys.actions';

export interface Survey {
    id: string;
    orderRef: DocumentReference;
    retailerRef: DocumentReference;
    staffRef: DocumentReference;
    questions: {
        questionId: string;
        question: string;
    }[]
    responses: {
        question: string;
        response: string;
    }[]
    responded: boolean;
}


// Entity adapter
export const surveyAdapter = createEntityAdapter<Survey>();
export interface State extends EntityState<Survey> { }


export const initialState: State = surveyAdapter.getInitialState();

// Reducer

export function surveyReducer(
    state: State = initialState,
    action: actions.SurveyActions) {

    switch (action.type) {

        case actions.ADDED:
            return surveyAdapter.addOne(action.payload, state);

        case actions.ADDEDALL:
            return surveyAdapter.addMany(action.payload, state);

        case actions.MODIFIED:
            return surveyAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state);

        case actions.MODIFIEDALL:
            return surveyAdapter.updateMany(action.payload.map(s => ({
                id: s.id,
                changes: s
            })), state);

        case actions.REMOVED:
            return surveyAdapter.removeOne(action.payload.id, state);

        case actions.REMOVEDALL:
            return surveyAdapter.removeMany(action.payload.map(s => s.id), state);
        default:
            return state;
    }

}

// Create the default selectors

export const getUserState = createFeatureSelector<State>('survey');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = surveyAdapter.getSelectors(getUserState);
