import { Component, NgZone, OnInit } from "@angular/core";
import axios from "axios";
import { environment } from "../../../environments/environment.prod";
import { Observable, BehaviorSubject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import moment from "moment";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MY_DATE_FORMATS, STATEMENT_DATE_FORMAT } from "src/app/utils";

@Component({
  selector: "app-review-charges",
  templateUrl: "./review-charges.component.html",
  styleUrls: ["./review-charges.component.scss"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class ReviewChargesComponent implements OnInit {
  public retailers$ = new BehaviorSubject<any[]>([]); // Observable to hold the list of retailers
  public dateControl = new FormControl(moment()); // Form control for date selection
  filterSubject$ = new BehaviorSubject<string>(""); // Observable for filtering data

  public loading = false; // Loading state for the component
  public selectedRetailer: any = {}; // Holds the currently selected retailer
  public editForm: FormGroup; // FormGroup for the modal form

  columnsDef = [
    { property: "name", heading: "Retailer Name" },
    { property: "email", heading: "Email" },
    { property: "amount", heading: "Grand Total ($)" },
    { property: "pendingCharge", heading: "Pending Charge ($)" },
    { property: "paid", heading: "Amount Paid ($)" },
    { property: "status", heading: "Status" },
    { property: "confirm", heading: "Confirm" },
    { property: "edit", heading: "Edit" }
  ];

  columns = this.columnsDef.map((c) => c.property); // Extracts column properties for table display

  constructor(
    private ngZone: NgZone,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.fetchPendingCharges(); // Fetch the list of pending charges on initialization

    // Initialize the form for editing retailer amounts
    this.editForm = this.fb.group({
      amount: [""], // Default value for the amount field
    });
  }

  /**
   * Opens the modal for editing a retailer's amount.
   * @param content - The modal content template.
   * @param retailer - The retailer object to edit.
   */
  open(content, retailer) {
    this.selectedRetailer = retailer; // Set the selected retailer

    // Set the default value for the amount in the form
    this.editForm.patchValue({
      amount: parseFloat(retailer.amount.replace(/[$,]/g, "").trim()), // Remove "$" and "," symbols, then convert to number
    });

    // Open the modal
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        () => {},
        () => {}
      );
  }

  /**
   * Confirms the edited amount for a retailer and sends the updated data to the server.
   */
  confirmEditedCharge() {
    const updatedAmount = this.editForm.value.amount; // Get the updated amount from the form
    const originalAmount = parseFloat(
      this.selectedRetailer.amount.replace(/[$,]/g, "").trim()
    ); 

    const updatedRetailer = {
      ...this.selectedRetailer,
      amount: updatedAmount,
      type: updatedAmount !== originalAmount ? "edited" : "original", // Add type property based on whether the amount was edited
    };

    // Call the API to confirm the charge
    this.sendPaymentCharge(updatedRetailer);

    // Close the modal
    this.modalService.dismissAll();
  }

  /**
   * Fetches the list of pending charges from the server.
   */
  fetchPendingCharges() {
    const selectedDate = this.dateControl.value.format("MMMM YYYY"); // Format the selected date as "Month Year"
    
    axios
      .post(
        `https://us-central1-${environment.domain}.cloudfunctions.net/fetchPendingCharges`,
        {
          data: { date: selectedDate },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {

        this.ngZone.run(() => {
          const formattedData = res.data.map((retailer) => ({
            ...retailer,
            pendingCharge: retailer.pending
              ? `$ ${(retailer.pendingCharge / 100).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : "$0.00",
            amount: retailer.data.platformFee
              ? `$ ${retailer.data.platformFee.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : "$0.00",
            loading: false, // Add loading property for each retailer
          }));
          this.retailers$.next(formattedData); // Update the observable with the formatted data
        });
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }

  /**
   * Fetches the list of pending charges from the server.
   */
  checkPaymentStatus() {
    this.loading = true;
    
    axios
      .post(
        `https://us-central1-${environment.domain}.cloudfunctions.net/checkPaymentStatus`,
        {
          data: {  },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.loading = false; 

        window.location.reload();
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }

  /**
   * Sends the retailer data to the server to confirm the charge.
   * @param retailer - The retailer object to confirm.
   */
  confirmCharge(content, retailer: any) {
    this.selectedRetailer = retailer; // Set the selected retailer

    // Open the modal
    this.modalService
      .open(content, { ariaLabelledBy: "confirm-payment" })
      .result.then(
        () => {},
        () => {}
      );
  }

  submitPaymentCharge() {
    const updatedRetailer = {
      ...this.selectedRetailer,
      type: "original", // Set type to "original"
    };

    // Call the API to confirm the charge
    this.sendPaymentCharge(updatedRetailer);

    // Close the modal
    this.modalService.dismissAll();
  }

  /**
   * Sends the retailer data to the server to confirm the charge.
   * @param retailer - The retailer object to confirm.
   */
  sendPaymentCharge(retailer: any) {
    this.loading = true; // Set loading to true for the component
    retailer.loading = true; // Set loading to true for the specific retailer

    const retailerData = retailer;

    if (retailerData.type != "edited") {
      retailerData.type = "original"; // Default to "original" if not edited
    }

    axios
      .post(
        `https://us-central1-${environment.domain}.cloudfunctions.net/stripeConfirmedRetailer`,
        {
          data: retailerData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        retailer.loading = false; // Set loading to false after submission
        this.loading = false; // Set loading to false for the component

        window.location.reload();
      })
      .catch((err) => {
        retailer.loading = false; // Set loading to false on error
        this.loading = false; // Set loading to false for the component
      });
  }

  /**
   * Handles the selection of a year in the date picker.
   * @param normalizedYear - The selected year as a moment object.
   * @param datepicker - The date picker instance.
   */
  public chosenYearHandler(
    normalizedYear: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const controlValue = this.dateControl.value; // Get the current date value
    controlValue.year(normalizedYear.year()); // Set the year
    this.dateControl.setValue(controlValue); // Update the date control

    // Switch to the month view
    datepicker.open(); // Reopen the datepicker to switch views
  }

  /**
   * Handles the selection of a month in the date picker.
   * @param normalizedMonth - The selected month as a moment object.
   * @param datepicker - The date picker instance.
   */
  public chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const controlValue = this.dateControl.value; // Get the current date value
    controlValue.month(normalizedMonth.month()); // Set the month
    this.dateControl.setValue(controlValue); // Update the date control

    // Fetch charges for the selected month/year
    this.fetchPendingCharges();

    datepicker.close(); // Close the date picker
  }
}
