import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { map, switchMap, publishReplay, refCount, first } from 'rxjs/operators';
import { User, Roles, Permission } from '../models/user.model';
import { Retailer } from '../retailer/retailer.reducer';
import { Customer } from '../customer/customer.reducer';
import { Store, ActionReducer, Action } from '@ngrx/store';
import { NotifyService } from '../shared/notify.service';
import { Updates } from '../updates/updates.reducer';

export class ActionTypes {
  static LOGOUT = '[App] logout';
  static LOGIN = '[App] logout';
}

export class Logout implements Action {
  readonly type = ActionTypes.LOGOUT;
}
export class Login implements Action {
  readonly type = ActionTypes.LOGIN;
}

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(action.type === ActionTypes.LOGOUT ? undefined : state, action);
  };
}

export function login(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(action.type === ActionTypes.LOGIN ? state : state, action);
  };
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$: Observable<User>;
  updates$: Observable<Updates>;
  user: User;
  retailer$?: Observable<Retailer>;
  customer$?: Observable<Customer>;
  customerId: string;
  retailerID: string;
  retailerRef?: DocumentReference;
  customerRef?: DocumentReference;
  permissions$: Observable<Permission[]>;
  loading = true;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private store: Store<any>,
    private notify: NotifyService
  ) {
    this.permissions$ = this.afs.collection<Permission>('permissions').snapshotChanges().pipe(
      map(actions => {
        const permissions: Permission[] = [];
        for (const action of actions) {
          const data = action.payload.doc.data() as Permission;
          const id = action.payload.doc.id;
          permissions.push({
            id,
            ...data
          });
        }
        return permissions;
      })
    );
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          const retval = this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          retval.subscribe(res => {
            this.user = res;
            if (res && res.retailerRef) {
              // const ref = "dD5XaEtvmuBq8xtST2Jf"
              this.retailerRef = res.retailerRef;
              this.updates$ = this.afs.doc<Updates>(`updates/${res.retailerRef.id}`).valueChanges();
               // this.retailerID = '94ENcXslLselub2CM5Is';
              this.retailer$ = this.afs.doc<Retailer>(`retailers/${res.retailerRef.id}`)
                .snapshotChanges()
                .pipe(
                  map(actions => {
                    const data = actions.payload.data() as Retailer;
                    const id = actions.payload.id;
                    this.loading = false;
                    return { id, ...data };
                  }),
                  publishReplay(1),
                  refCount()
                );
            } else {
              this.retailer$ = undefined;
               // this.retailerID = undefined;
            }
            if (res && res.customerRef) {
              this.customerRef = res.customerRef;
              this.customerId = res.customerRef.id;
              this.customer$ = this.afs.doc<Customer>(`customers/${res.customerRef.id}`).valueChanges();
            } else {
              this.customer$ = undefined;
            }
          });
          return retval;
        } else {
          return of(null);
        }
      })
    );
  }
///// Role-based Authorization //////

static isAdmin(user: User): boolean {
  const allowed = ['admin'];
  return this.checkAuthorization(user, allowed);
}

static isRetailer(user: User): boolean {
  const allowed = ['retailer'];
  return this.checkAuthorization(user, allowed);
}

static isCustomer(user: User): boolean {
  const allowed = ['customer'];
  return this.checkAuthorization(user, allowed);
}

// determines if user has matching role
private static checkAuthorization(user: User, allowedRoles: string[]): boolean {
  if (!user) { return false; }
  for (const role of allowedRoles) {
    if ( user && user.roles && user.roles[role] ) {
      return true;
    }
  }
  return false;
}

 isAdmin(user: User): boolean {
  return AuthService.isAdmin(user);
}

 isRetailer(user: User): boolean {
  return AuthService.isRetailer(user);
}

 isCustomer(user: User): boolean {
  return AuthService.isCustomer(user);
}
  async emailLogin(email: string, password: string) {
    const credential = await this.afAuth.signInWithEmailAndPassword(email, password)
      .catch(error => this.handleError(error) );
    if (credential) {
      return this.updateUserData(credential.user);
    }
  }

  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    // const credential = await this.afAuth.auth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async signOut() {
    await this.afAuth.signOut();
    this.store.dispatch(new Logout());
    return this.router.navigate(['/login']);
  }

  public updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data = {... user};
    userRef.set(data, { merge: true }).then(() => {
      userRef.get().pipe(first()).subscribe(doc => {
        const updatedUser = doc.data();
        this.store.dispatch(new Login());
        if (updatedUser.acceptedTerms) {
          this.router.navigate(['/landing']);
        } else {
          this.router.navigate(['/terms']);
        }
        return updatedUser;
      });
    });
  }

    //// Email/Password Auth ////

    emailSignUp(email: string, password: string) {
      return this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(user => {
          return this.updateUserData(user); // create initial user document
        })
        .catch(error => this.handleError(error) );
    }

    register(email: string, password: string, roles: Roles, permissionId, retailerId: string, customerId: string) {
      return this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(cred => {
          const data = {
            uid: cred.user.uid,
            email: cred.user.email,
            displayName: cred.user.displayName,
            photoURL: cred.user.photoURL,
            roles: {...roles},
            permissionRef: this.afs.firestore.doc('permissions/' + permissionId),
            retailerRef: this.afs.firestore.doc('retailers/' + retailerId),
            customerRef: this.afs.firestore.doc('customers/' + customerId)
          };
          const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${cred.user.uid}`);
          return userRef.set(data, { merge: true });
        })
        .catch(error => this.handleError(error) );
    }

    // If error, console log and notify user
    private handleError(error) {
      console.error(error);
      this.notify.update(error.message, 'danger');
    }
}
