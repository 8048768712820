import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';

import { Retailer } from './retailer.reducer';
import * as retailerActions from './retailer.actions';

import {
  AngularFirestore
} from '@angular/fire/firestore';

import { switchMap, mergeMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { logAction } from '../shared/functions';
import { Notifications } from '../settings/notifications/notification.settings.reducer';

@Injectable()
export class RetailerEffects {

    @Effect()
    query$: Observable<Action> = this.actions$.pipe(
      ofType(retailerActions.QUERY),
      switchMap(action => {
        return this.afs.collection<Retailer>('retailers', ref =>  {
          return ref.where('isDeleted', '==', false);
        })
        .stateChanges();
      }),
      mergeMap(actions =>  actions),
      map(action => {
        return {
          type: `[retailer] ${action.type}`,
          payload: {
            id: action.payload.doc.id,
            ...action.payload.doc.data()
          }
        };
      })
    );

    @Effect()
    queryAll$: Observable<Action> = this.actions$.pipe(
      ofType(retailerActions.QUERYALL),
      switchMap(() => {
        return this.afs.collection<Retailer>('retailers', ref => ref).stateChanges();
      }),
      mergeMap(actions => actions),
      map(action => {
        return {
          type: `[retailer] ${action.type}`,
          payload: {
            id: action.payload.doc.id,
            ...action.payload.doc.data()
          }
        };
      })
    );

    @Effect() update$: Observable<Action> = this.actions$.pipe(
      ofType(retailerActions.UPDATE),
        map((action: retailerActions.Update) => action),
        switchMap(data => {
            const ref = this.afs.doc<Retailer>(`retailers/${data.id}`);
            logAction({
              action: 'Retailer updated',
              user: data.user,
              retailerId: data.id
            }, this.afs);
            delete data.changes.id;
            return from( ref.update(data.changes) );
        }),
        map(() => new retailerActions.Success())
    );

    @Effect() add$: Observable<Action> = this.actions$.pipe(
      ofType(retailerActions.ADD),
        map((action: retailerActions.Add) => action),
        switchMap(async (data) => {
          const notificationsCol = this.afs.collection<Notifications>(`notifications`);
            const col = this.afs.collection<Retailer>('retailers');
            delete data.retailer.id;
            const ref = await col.add(data.retailer);
            logAction({
              action: 'Retailer added',
              user: data.user,
              retailerId: ref.id
            }, this.afs);
            data.retailer.id = ref.id;
            return from(notificationsCol.doc(ref.id).set(this.initializeNotifications(data.retailer)));
        }),
        map(() => new retailerActions.Success())
    );

    @Effect() delete$: Observable<Action> = this.actions$.pipe(
      ofType(retailerActions.DELETE),
        map((action: retailerActions.Delete) => action),
        switchMap(data => {
          const ref = this.afs.doc<Retailer>(`retailers/${data.id}`);
          logAction({
            action: 'Retailer deleted',
            user: data.user,
            retailerId: data.id
          }, this.afs);
          return from( ref.update({ isDeleted: true }) );
        }),
        map(() => new retailerActions.Success())
    );

    private initializeNotifications (retailer: Retailer) {
      const notifications: Notifications = {
        id: retailer.id,
        newOrders: {
          emailFuneralDirector: true,
          emailFuneralHomeAccounts: true,
          emailRetailer: true,
          remotePrint: true,
          textRetailer: true
        },
        confirmedOrders: {
          emailFuneralDirector: true,
          emailFuneralHomeAccounts: true
        },
        assignedOrders: {
          textVaultSettler: true,
          textFuneralDirector: true,
          textRetailer: true
        },
        deliveredOrders: {
          textFuneralDirector: true,
          textRetailer: true
        },
        nonDeliveredOrders: {
          textVaultSettler: true,
          textFuneralDirector: false,
          textRetailer: true
        },
        twentyFourHourNotification: {
          emailCustomer: true,
          emailRetailer: true
        },
        twelveHourNotification: {
          emailCustomer: true,
          emailRetailer: true
        },
        generalInformation: {
          retailerPhoneNumbers: [retailer?.cellPhone],
          retailerEmails: [retailer?.email],
          faxNumbers: [retailer?.fax]
        },
        surveys: {
          sendSurvey: true,
          sendSurveyResults: {
            email: true,
            sms: true
          }
        },
        stock: {
          sms: true,
          email: false
        }
      };
      return notifications;
    }

    constructor(private actions$: Actions, private afs: AngularFirestore) { }
}
