import { FormGroup } from '@angular/forms';
import { ComponentCanDeactivate } from '../services/component-can-deactivate';

export abstract class FormCanDeactivate extends ComponentCanDeactivate {

 abstract get form(): FormGroup;

 canDeactivate(): boolean {
      return !this.form.dirty;
  }
}
