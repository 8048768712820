import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector } from "@ngrx/store";
import { Statement } from "../models/models";
import * as actions from './admin.actions';


export interface Admin {
    id?: string;
    statements?: Statement[];
    invoices?: Statement[];
}

export const adminAdapter = createEntityAdapter<Admin>();
export interface State extends EntityState<Admin> { }

export const initialState: State = adminAdapter.getInitialState();

export function adminReducer(state: State = initialState, action: actions.AdminActions) {
    switch (action.type) {
        case actions.ADDED:
            return adminAdapter.addOne(action.payload, state);

        case actions.ADDALL:
            return adminAdapter.addMany(action.payload, state);

        case actions.MODIFIED:
            return adminAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state);

        case actions.MODIFIEDALL:
            return adminAdapter.updateMany(action.payload.map(c => ({
                id: c.id,
                changes: c
            })), state);

        case actions.UPDATE:
            return adminAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state);

        case actions.REMOVED:
            return adminAdapter.removeOne(action.payload.id, state);

        case actions.REMOVEDALL:
            return adminAdapter.removeMany(action.payload.map(c => c.id), state);
        default:        
            return state
    }
}

export const getAdminState = createFeatureSelector<State>('admin');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adminAdapter.getSelectors(getAdminState);
