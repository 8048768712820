import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;

  constructor(
    public afAuth: AngularFireAuth,
    public fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.forgotPassForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
        ]
      ]
    });
  }

  get email() { return this.forgotPassForm.get('email'); }

  forgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

}
