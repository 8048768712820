import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatementsComponent } from './statements.component';
import { CdkTableModule } from '@angular/cdk/table';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { UploadsModule } from '../uploads/uploads.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { TableComponent } from './table/table.component';
import { HttpClientModule } from '@angular/common/http';
import { AdminModule } from '../admin/admin.module';



@NgModule({
  declarations: [StatementsComponent, TableComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    StoreModule,
    EffectsModule,
    NgbModule,
    AngularFirestoreModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatSliderModule,
    MatDialogModule,
    UploadsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    HttpClientModule,
    AdminModule
  ],
  exports: [
    StatementsComponent
  ]
})
export class StatementsModule { }
