import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { customerReducer } from './customer.reducer';
import { CustomerEffects } from './customer.effects';
import { TableComponent } from './table/table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewComponent } from './new/new.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomerRoutingModule } from './customer.routing.module';
import { SharedModule } from '../shared/shared.module';
import { StatementsModule } from '../statements/statements.module';
import { InvoicesModule } from '../invoices/invoices.module';

@NgModule({
  imports: [
    CommonModule,
    CustomerRoutingModule,
    StoreModule.forFeature('customer', customerReducer),
    EffectsModule.forFeature([CustomerEffects]),
    CdkTableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSliderModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    SharedModule,
    StatementsModule,
    InvoicesModule,
    NgxDropzoneModule,
    MatSnackBarModule
  ],
  exports: [CustomerListComponent],
  declarations: [
    CustomerListComponent,
    TableComponent,
    NewComponent]
})
export class CustomerModule { }
