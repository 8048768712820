import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import * as surveyActions from './surveys.actions';
import { Survey } from "./surveys.reducer";
@Injectable()
export class SurveyEffects {
    
    constructor(private actions$: Actions, private afs: AngularFirestore) { }

    query$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(surveyActions.QUERY),
        switchMap(() => {
            return this.afs.collection<Survey>('surveys', ref =>  {
                return ref;
            }).stateChanges();
        }),
        map((actions) => {
            let changes = [];
            if (actions) {
            const addActions = actions.filter((val: any) => val.type === 'added');
            const removeActions = actions.filter((val: any) => val.type === 'removed');
            const modifyActions = actions.filter((val: any) => val.type === 'modified');
            if (addActions.length > 0) {
                changes.push( {
                type: surveyActions.ADDEDALL,
                payload: addActions.map((item: any) => {
                    const survey = item.payload.doc.data() as Survey;
                    return {
                    id: item.payload.doc.id,
                    ref: item.payload.doc.ref,
                    ...survey
                    }
                })
                })
            }
            if (removeActions.length > 0) {
                changes.push({
                type: surveyActions.REMOVEDALL,
                payload: removeActions.map(
                    (item: any) => ({
                    id: item.payload.doc.id
                    }))
                })
            }
            if (modifyActions.length > 0) {
                changes.push( {
                type: surveyActions.MODIFIEDALL,
                payload: addActions.map((item: any) => {
                    const survey = item.payload.doc.data() as Survey;
                    return {
                    id: item.payload.doc.id,
                    ref: item.payload.doc.ref,
                    ...survey
                    }
                })
                })
            }
            }
            return changes;
        }),
        filter(surveys => surveys.length > 0),
        switchMap(surveys => from(surveys))
        )
    });

    queryUserSurveys$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(surveyActions.QUERYUSERSURVEYS),
            switchMap((data: surveyActions.QueryUserSurveys) => {
                return this.afs.collection<Survey>('surveys', ref => {
                    return ref.where('retailerRef', '==', data.user.retailerRef);
                }).stateChanges();
            }),
            map((actions) => {
                let changes = [];
            if (actions) {
            const addActions = actions.filter((val: any) => val.type === 'added');
            const removeActions = actions.filter((val: any) => val.type === 'removed');
            const modifyActions = actions.filter((val: any) => val.type === 'modified');
            if (addActions.length > 0) {
                changes.push( {
                type: surveyActions.ADDEDALL,
                payload: addActions.map((item: any) => {
                    const survey = item.payload.doc.data() as Survey;
                    return {
                    id: item.payload.doc.id,
                    ref: item.payload.doc.ref,
                    ...survey
                    }
                })
                })
            }
            if (removeActions.length > 0) {
                changes.push({
                type: surveyActions.REMOVEDALL,
                payload: removeActions.map(
                    (item: any) => ({
                    id: item.payload.doc.id
                    }))
                })
            }
            if (modifyActions.length > 0) {
                changes.push( {
                type: surveyActions.MODIFIEDALL,
                payload: addActions.map((item: any) => {
                    const survey = item.payload.doc.data() as Survey;
                    return {
                    id: item.payload.doc.id,
                    ref: item.payload.doc.ref,
                    ...survey
                    }
                })
                })
            }
            }
            return changes;
            }),
            filter(surveys => surveys.length > 0),
            switchMap(surveys => from(surveys))
        )
    });

    updateSurvey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(surveyActions.UPDATE),
            map((action: surveyActions.Update) => action),
            switchMap(data => {
                const ref = this.afs.doc<Survey>(`surveys/${data.id}`);
                delete data.changes.id;
                return from(ref.update(data.changes));
            }),
            map(() => {
                console.log('done');
                return new surveyActions.Success()
            })
        )
    });
}
