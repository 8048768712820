import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import {
  filter,
  finalize,
  map,
  distinctUntilChanged,
  switchMap,
} from "rxjs/operators";
import { of } from "rxjs";
import {
  Option,
  Retailer,
  State,
  selectAll as selectAllRetailers,
} from "../retailer.reducer";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { UploadPageComponent } from "src/app/uploads/upload-page/upload-page.component";
import { AngularFireFunctions } from "@angular/fire/functions";
import { Router } from "@angular/router";
import { NotifyService } from "src/app/shared/notify.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { Product } from "src/app/products/products.reducer";
import { AuthService } from "src/app/services/auth.service";
import { User } from "src/app/models/user.model";
import { Store } from "@ngrx/store";
import { Add, Update } from "src/app/products/products.actions";
import firebase from "firebase";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { orderBy, groupBy } from "lodash";
import * as XLSX from "xlsx";
interface IProgressBar {
  progress: number;
}
@Component({
  selector: "app-option-list",
  templateUrl: "./option-list.component.html",
  styleUrls: ["./option-list.component.scss"],
})
export class OptionListComponent implements OnInit {
  @ViewChild("upload", { static: false }) upload: UploadPageComponent;
  @ViewChild("formDialog", { static: true }) formDialog;
  @Input() options: Option[];
  @Input() serviceType: Option[];
  @Input() serviceExtra: Option[];
  @Input() showCategory: boolean;
  @Input() retailer: Retailer;
  @Input() isAdmin: boolean;
  @Input() addImage: boolean;
  @Input() tabNumber: number;
  @Output() changes = new EventEmitter<Option[]>();
  imageUrls: string[] = [];
  form: FormGroup;
  formedit: FormGroup;
  editMode = false;
  saving = false;
  orginalEdit: Product | Option;
  vaultImage: any;
  columns = ["name", "price", "edit", "delete"];
  loading: boolean = false;
  title = "dropzone";
  isHovering: boolean;
  uploadedFileNames: string[] = [];
  files: File[] = [];
  imagePreviews: string[] = [];
  percentage: Observable<number>;

  newImageName: number;
  showImage: boolean = false;

  columnsDef = [
    { heading: "Category", property: "category", prefix: "" },
    { heading: "Name", property: "name", prefix: "" },
    { heading: "Price", property: "price", prefix: "$" },
    { heading: "quantity", property: "quantity", prefix: "" },
  ];

  validationMessages = {
    category: [{ type: "required", message: "Category is required." }],
    name: [{ type: "required", message: "Name is required." }],
    price: [{ type: "required", message: "Price  is required." }],
    quantity: [{ type: "quantity", message: "quantity is required." }],
  };

  // Private data source
  heroes$: Observable<any>;
  activeTab: string;
  productsData: any[] = [];
  serviceTypesData: any[] = [];
  ServiceExtraData: any[] = [];
  // Public Observable for table
  dataSource$: Observable<any[]>;
  public selectedOption: Product;
  public selectedServiceType: Option;
  public selectedServiceExtra: Option;

  minMax$: Observable<any>;
  private temp: any;
  private subscriptions: Subscription[] = [];
  isImage = false;
  // Pagination data
  currentPage$ = new BehaviorSubject(1);
  dataOnPage$: Observable<any[]>;
  pageSize = 25;

  selectedFile: File = null;
  downloadUrl: Observable<string>;
  fileUrl: string;
  oldImageName: number;
  progressBar: IProgressBar = { progress: 0 };
  user: User;
  serviceTypes: any;
  buttonClicked: boolean = false;
  private hasRun: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public changeDetectorRef: ChangeDetectorRef,
    private fns: AngularFireFunctions,
    private router: Router,
    private notify: NotifyService,
    private storage: AngularFireStorage,
    public auth: AuthService,
    private store: Store<State>,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar
  ) {}

  loadingCsv = false;
  csvNotValid = false;
  csvUploaded = false;

  csvData: any[] = [];

  ngOnInit() {
    if (this.showCategory) {
      this.columns.splice(0, 0, "category");
    }
    this.createForm();
    this.subscriptions.push(
      this.auth.user$.subscribe(
        (user) => {
          if (user) {
            this.user = user;
            this.loadTable();
          } else {
            console.log("");
          }
        },
        (error) => {
          console.error("Error fetching user data:", error);
          // Handle error
        }
      )
    );
  }

  openBulkUploadModal(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  downloadTemplate() {
    const templateRef = this.storage.ref("products_template.xlsx");
    templateRef.getDownloadURL().subscribe((url) => {
      window.open(url);
    });
  }

  onExcelFileSelected(event: any) {
    if (!event || !event.addedFiles || event.addedFiles.length === 0) {
      return; // Exit if event or addedFiles is undefined or empty
    }

    this.loadingCsv = true;
    this.csvNotValid = true;

    if (event.addedFiles.length > 1) {
      alert("You can only upload one file at a time.");
      this.loadingCsv = false;
      return;
    }

    const file = event.addedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonObj = XLSX.utils.sheet_to_json(worksheet);

        // Validation logic here
        if (this.validateCsvData(jsonObj)) {
          this.csvNotValid = false; // xlsx is valid, enable upload button
          this.csvUploaded = true;
          this.csvData = jsonObj;
        } else {
          this.csvNotValid = true; // xlsx is invalid, keep upload button disabled
        }
        this.loadingCsv = false;
      };
      reader.readAsArrayBuffer(file);
    } else {

      this.loadingCsv = false;
    }
  }

  validateCsvData(jsonObj: any[]): boolean {
    // Check if every row of the CSV has the required fields and valid data
    return jsonObj.every((row) => {
      // checking fields exist and are not null or empty
      const hasAllFields = row.category && row.product_name && row.price;

      // Check if the 'price' field is a valid number and not text
      // This check assumes 'price' should be a positive value
      const priceIsValid =
        !isNaN(parseFloat(row.price)) &&
        isFinite(row.price) &&
        parseFloat(row.price) > 0;

      return hasAllFields && priceIsValid;
    });

  }

  addBulkProductsToDb() {
    this.addBulkProducts(this.csvData);
  }

  loadTable() {
    this.auth.user$
      .pipe(
        filter((user) => user?.roles?.retailer), // Filter out non-retailer users
        switchMap((user) => {
          if (this.tabNumber === 1) {
            // Fetch and assign products data for Tab 1
            return this.afs
              .collection("products", (ref) =>
                ref
                  .where("retailerRef", "==", user.retailerRef) // Use retailerRef directly
                  .where("category", ">", "") // Filter out documents where category is empty
                  .orderBy("category")
                  .orderBy("name")
              )
              .snapshotChanges()
              .pipe(
                map((actions) =>
                  actions.map((a) => {
                    const data = a.payload.doc.data() as any;
                    const productId = a.payload.doc.id;
                    const sanitizedData = this.handleNullValues(data);
                    return { id: productId, ...sanitizedData };
                  })
                ),
                map((products) => this.getUniqueProducts(products)) // Filter out duplicates
              );
          } else if (this.tabNumber === 2) {
            // Sort serviceType by name
            const sortedServiceType = orderBy(
              this.serviceType || [],
              ["name"],
              ["asc"]
            );
            return of(sortedServiceType);
          } else if (this.tabNumber === 3) {
            // Sort serviceExtra by name
            const sortedServiceExtra = orderBy(
              this.serviceExtra || [],
              ["name"],
              ["asc"]
            );
            return of(sortedServiceExtra);
          } else {
            return of([]); // Return empty array for unknown tab numbers
          }
        })
      )
      .subscribe(
        (data) => {
          if (this.tabNumber === 1) {
            this.productsData = data;
          } else if (this.tabNumber === 2) {
            this.serviceTypesData = data;
          } else if (this.tabNumber === 3) {
            this.ServiceExtraData = data;
          }
          this.dataSource$ = of(data); // Update dataSource$ for the table
          this.changeDetectorRef.detectChanges(); // Trigger change detection
        },
        (error) => {
          console.error("Error loading data:", error);
        }
      );
  }

  // Helper function to get unique products based on name and category
  getUniqueProducts(products: any[]): any[] {
    const uniqueProducts = {};
    products.forEach((product) => {
      const key = `${product.category}-${product.name}`; // Create a unique key based on category and name
      if (!uniqueProducts[key]) {
        uniqueProducts[key] = product;
      }
    });
    return Object.values(uniqueProducts); // Convert object back to array
  }

  private handleNullValues(data: any): any {
    // Filter out null, undefined, or empty string values from the data object
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  createForm() {
    this.form = this.fb.group({
      category: [""],
      name: ["", Validators.required],
      price: ["", Validators.required],
      dropzoneImage: [""],
    });
  }

  resetFields() {
    this.form = this.fb.group({
      category: new FormControl(""),
      name: new FormControl("", Validators.required),
      price: new FormControl("", Validators.required),
    });
  }

  openModal(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  addService() {
    console.log("Edit ...", this.editMode);
    console.log("Add ",this.addImage)
    this.loading = true;
    if (this.tabNumber === 1) {
      if (this.editMode) {
        this.editProduct();
        this.changeDetectorRef.detectChanges();
      } else {
        this.addProduct();
        this.changeDetectorRef.detectChanges();
      }
      // this.add();
      this.changeDetectorRef.detectChanges();
    } else if (this.tabNumber === 2) {
      const serviceType = {
        name: this.form.get("name").value,
        price: this.form.get("price").value,
      };
      if (this.editMode) {
        const index = this.serviceType
          .map((opt) => opt.name)
          .findIndex((opt) => opt === this.orginalEdit.name);
        if (index !== -1) {
          this.serviceType[index] = serviceType;
          this.editMode = false;
          this.orginalEdit = undefined;
          this.resetFields();
          this.changes.emit(this.serviceType);
          this.dataSource$ = new Observable((subscriber) => {
            return subscriber.next(this.serviceType);
          });
          this.changeDetectorRef.detectChanges();
          this.modalService.dismissAll();
        }
      } else {
        this.serviceType.push(serviceType);
        this.changes.emit(this.serviceType);
        this.resetFields();
        this.dataSource$ = new Observable((subscriber) => {
          return subscriber.next(this.serviceType);
        });
        this.changeDetectorRef.detectChanges();
      }
      this.modalService.dismissAll();
    } else if (this.tabNumber === 3) {
      const serviceExtra = {
        name: this.form.get("name").value,
        price: this.form.get("price").value,
      };
      if (this.editMode) {
        const index = this.serviceExtra
          .map((opt) => opt.name)
          .findIndex((opt) => opt === this.orginalEdit.name);
        if (index !== -1) {
          this.serviceExtra[index] = serviceExtra;
          this.editMode = false;
          this.orginalEdit = undefined;
          this.resetFields();
          this.dataSource$ = new Observable((subscriber) => {
            return subscriber.next(this.serviceExtra);
          });
          this.changeDetectorRef.detectChanges();
        }
        this.modalService.dismissAll();
      } else {
        this.serviceExtra.push(serviceExtra);
        this.resetFields();
        this.dataSource$ = new Observable((subscriber) => {
          return subscriber.next(this.serviceExtra);
        });
        this.changeDetectorRef.detectChanges();
      }
      this.modalService.dismissAll();
    }
    this.loading = false;
  }

  public addBulkProducts(productsData: any[]) {
    // Check if the function has already been executed
    if (this.hasRun) {
      console.log("Function already executed once");
      return;
    }
    // Set the flag to indicate that the function is running
    this.hasRun = true;

    // Array to store promises for adding products
    const addProductPromises = [];

    let imagePosition = 0;
    productsData.forEach((productData) => {
      const date = new Date();
      const product: Product = {
        category: productData.category,
        name: productData.product_name,
        price: productData.price,
        image: this.imageUrls[imagePosition++],
        imageName: null,
        retailerRef: this.user.retailerRef,
        quantity: 0,
        history: [],
        isDeleted: false,
        createAt: date,
        updatedAt: date,
      };

      // Perform validation and existence check synchronously
      if (!this.isValidNameAndCategory(product.name, product.category)) {
        this.snackBar.open("Invalid product: " + product.name, "Close", {
          duration: 5000,
          panelClass: ["error-snackbar"],
          verticalPosition: "top",
        });
      } else if (this.productExists(product)) {
        // If the product already exists, show exists message
        this.snackBar.open("Product already exists: " + product.name, "Close", {
          duration: 5000,
          panelClass: ["warning-snackbar"],
          verticalPosition: "top",
        });
      } else {
        const addProductPromise = this.afs
          .collection("products")
          .add(product)
          .then((docRef) => docRef.id)
          .catch((error) => null);
        addProductPromises.push(addProductPromise);
      }
    });

    Promise.all(addProductPromises)
      .then((results) => {
        const successfulAdds = results.filter((result) => result !== null);
        if (successfulAdds.length > 0) {
          // Show success message if products were added
          this.snackBar.open(
            `${successfulAdds.length} Products Added to Inventory!`,
            "Close",
            {
              duration: 7000,
              panelClass: ["success-snackbar"],
              verticalPosition: "top",
            }
          );
        } else {
          // Show warning message if no products were added
          this.snackBar.open(
            "No new products were added. They may already exist or be invalid.",
            "Close",
            {
              duration: 7000,
              panelClass: ["warning-snackbar"],
              verticalPosition: "top",
            }
          );
        }
        this.loadTable();
        this.resetForm();
        // Dismiss the modal
        this.modalService.dismissAll();
      })
      .catch((error) => {
        this.snackBar.open("Error adding products:", error, {
          duration: 7000,
          panelClass: ["error-snackbar"],
          verticalPosition: "top",
        });
      })
      .finally(() => {
        this.hasRun = false;
        // Reset variables
        this.resetVariables();
      });
  }

  public addProduct() {
    console.log("Images ...",this.imageUrls)
    if (this.hasRun) {
      console.log("Function already executed once");
      return;
    }
    this.hasRun = true;
    this.showImage = false;
    const date = new Date();
    const product: Product = {
      category: this.form.get("category").value,
      name: this.form.get("name").value,
      price: this.form.get("price").value,
      image: this.imageUrls[0] || null,
      imageName: null,
      retailerRef: this.user.retailerRef,
      quantity: 0,
      history: [],
      isDeleted: false,
      createAt: date,
      updatedAt: date,
    };
    // Check if the name and category are valid
    if (!this.isValidNameAndCategory(product.name, product.category)) {
      this.snackBar.open("Invalid name or category", "Close", {
        duration: 5000,
        panelClass: ["error-snackbar"],
        verticalPosition: "top",
      });
      return;
    }
    console.log("Image: ",this.imageUrls);
    // Check if the product already exists
    if (this.productExists(product)) {
      this.snackBar.open("Product already exists", "Close", {
        duration: 5000,
        panelClass: ["error-snackbar"],
        verticalPosition: "top",
      });
      return;
    }

    if (this.addImage && !this.editMode) {
      if (
        this.newImageName &&
        this.newImageName !== this.oldImageName &&
        this.oldImageName
      ) {
        try {
          console.log("Product Image: ",this.vaultImage)
          product.image = this.vaultImage;
          this.storage
            .ref(`VaultsImages/${this.oldImageName}`)
            .getDownloadURL()
            .subscribe((url) => {
              this.storage.storage.refFromURL(url).delete();
            });
        } catch (error) {
          this.snackBar.open("Delete failed", error);
        }
      }
    }
    this.afs
      .collection("products")
      .add(product)
      .then((docRef) => {
        this.snackBar.open("Product Added to Inventory!", "Close", {
          duration: 5000,
          panelClass: ["success-snackbar"],
          verticalPosition: "top",
        });
        this.loadTable(); // Reload data after adding the new product
        this.resetForm();
        // Dismiss the modal
        this.modalService.dismissAll();
        // Reset hasRun flag so the function can run again
        this.hasRun = false;
      })
      .catch((error) => {
        // Error message
        this.snackBar.open("Error adding product:", error, {
          duration: 5000,
          panelClass: ["error-snackbar"],
          verticalPosition: "top",
        });
        // Reset hasRun flag if there was an error
        this.hasRun = false;
      });

    this.resetVariables();
    //this.imageUrls = []; //reset images
  }

  resetVariables() {
    this.loadingCsv = false;
    this.csvNotValid = false;
    this.csvUploaded = false;
    this.imageUrls = [];
    this.vaultImage = "";
    this.csvData = [];
  }
  
  public editProduct() {
    console.log("Edit product running")
    console.log("Image Urls",this.imageUrls)

    if (this.editMode) {

      const date = new Date();
      this.subscriptions.push(
        this.auth.user$.subscribe((user) => {
          this.user = user;
          const product: Product = {
            category: this.form.get("category").value,
            name: this.form.get("name").value,
            price: this.form.get("price").value,
            image: this.imageUrls[0] || null,
            imageName: null,
            retailerRef: this.user.retailerRef,
            quantity: 0,
            history: [],
            isDeleted: false,
            createAt: date,
            updatedAt: date,
          };
          // Check if the name and category are valid
          if (!this.isValidNameAndCategory(product.name, product.category)) {
            this.snackBar.open("Invalid name or category", "Close", {
              duration: 5000,
              panelClass: ["error-snackbar"],
              verticalPosition: "top",
            });
            return;
          }
          if (this.addImage && this.editMode) {
            if (
              this.newImageName &&
              this.newImageName !== this.oldImageName &&
              this.oldImageName
            ) {
              try {
                product.image = this.vaultImage;
                this.storage
                  .ref(`VaultsImages/${this.oldImageName}`)
                  .getDownloadURL()
                  .subscribe((url) => {
                    this.storage.storage.refFromURL(url).delete();
                  });
              } catch (error) {
                this.snackBar.open("Delete failed", error);
              }
            }
          }
          this.updateProduct();
          this.editMode = false;
          this.orginalEdit = undefined;
          this.loadTable();
          this.snackBar.open("Product updated successfully", "Close", {
            duration: 5000,
            panelClass: ["success-snackbar"],
            verticalPosition: "top",
          });
          this.modalService.dismissAll();
          this.resetForm();
          this.imageUrls=[]; //reset images
          this.vaultImage = "";
        })
      );
    }
  }

  onCancelClick() {
    this.modalService.dismissAll();
    this.imageUrls = [];
    this.vaultImage = "";
    this.editMode = false;
    this.resetForm();
  }

  private resetForm() {
    this.form.reset();
  }

  private isValidNameAndCategory(name: string, category: string): boolean {
    return name.trim() !== "" && category.trim() !== "";
  }

  private productExists(newProduct: Product): boolean {
    return this.options.some(
      (product) =>
        product.name === newProduct.name &&
        product.category === newProduct.category
    );
  }

  private updateProduct() {
    const date = new Date();
    this.store.dispatch(
      new Update(
        this.orginalEdit.id,
        {
          category: this.form.get("category").value,
          name: this.form.get("name").value,
          price: this.form.get("price").value,
          image: this.imageUrls[0] || null,
          imageName: null,
          updatedAt: date,
        },
        this.user
      )
    );
    this.resetFields();
    this.resetForm();
    this.imageUrls = []; //reset images
  }

  public edit(option: Product) {
    this.showImage = true;
    this.vaultImage = option.image || null;
    this.oldImageName = option.imageName || null;
    this.editMode = true;
    this.orginalEdit = option;
    this.form.get("category").setValue(option.category);
    this.form.get("name").setValue(option.name);
    this.form.get("price").setValue(option.price);
    this.open(this.formDialog);
  }

  public onSelect(option: Product) {
    this.selectedOption = option;
  }

  public delete(name: string): void {
    switch (this.tabNumber) {
      case 1:
        this.deleteProduct(this.selectedOption);
        break;
      case 2:
        this.deleteServiceType(name);
        break;
      case 3:
        this.deleteserviceExtra(name);
        break;
      default:
        break;
    }
  }

  public deleteProduct(option: Product) {
    this.modalService.dismissAll();
    this.options.splice(this.options.indexOf(option), 1);
    // this.storage.storage.ref(`VaultsImages/${option.name}`).delete();
    firebase.firestore().collection("products").doc(option.id).delete();
    this.dataSource$ = new Observable((subscriber) => {
      return subscriber.next(this.options);
    });
    this.changeDetectorRef.detectChanges();
  }

  deleteServiceType(name: string): void {
    this.modalService.dismissAll();
    let updatedServiceTypes: any[];
    this.auth.user$.subscribe((user) => {
      if (user.roles?.retailer) {
        const retailerRef = user.retailerRef.id;
        const retailerDocRef = firebase
          .firestore()
          .collection("retailers")
          .doc(retailerRef);
        retailerDocRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const retailerData = doc.data();
              if (retailerData && retailerData.serviceType) {
                const serviceTypesData = retailerData.serviceType;
                updatedServiceTypes = serviceTypesData.filter(
                  (item) => item.name !== name
                );

                return retailerDocRef.update({
                  serviceType: updatedServiceTypes,
                });
              } else {
                console.log("Service types field not found or is empty!");
              }
            } else {
              console.log("Retailer document not found!");
            }
          })
          .then(() => {
            console.log("Service type deleted successfully.");
            // Update local data, emit changes, and update observable
            this.serviceType = updatedServiceTypes;
            this.changes.emit(this.serviceType);
            this.dataSource$ = new Observable((subscriber) => {
              subscriber.next(this.serviceType);
            });
            this.changeDetectorRef.detectChanges();
          })
          .catch((error) => {
            console.error("Error deleting service type:", error);
          });
      }
    });
  }

  deleteserviceExtra(name: string): void {
    this.modalService.dismissAll();
    let updatedServiceExtras: any[];
    this.auth.user$.subscribe((user) => {
      if (user.roles?.retailer) {
        const retailerRef = user.retailerRef.id;
        const retailerDocRef = firebase
          .firestore()
          .collection("retailers")
          .doc(retailerRef);

        retailerDocRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const retailerData = doc.data();
              console.log(retailerData);
              if (retailerData && retailerData.serviceExtras) {
                const serviceExtrasData = retailerData.serviceExtras;
                console.log(serviceExtrasData);
                updatedServiceExtras = serviceExtrasData.filter(
                  (item) => item.name !== name
                );
                return retailerDocRef.update({
                  serviceExtras: updatedServiceExtras,
                });
              } else {
                console.log("Service EX field not found or is empty!");
              }
            } else {
              console.log("Retailer document not found!");
            }
          })
          .then(() => {
            console.log("Service ex deleted successfully.");
            this.serviceExtra = updatedServiceExtras;
            this.dataSource$ = new Observable((subscriber) => {
              subscriber.next(this.serviceExtra);
            });
            this.changeDetectorRef.detectChanges();
          })
          .catch((error) => {
            console.error("Error deleting service ex:", error);
          });
      }
    });
  }

  open(content) {
    
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {},
        () => {}
      );
  }

  onFileSelected(event) {
    console.log("File selected .....")
    // Filter out files that have already been uploaded
    const newFiles = event.addedFiles.filter(
      (file) => !this.uploadedFileNames.includes(file.name)
    );
    // Update the list of uploaded files
    this.uploadedFileNames.push(...newFiles.map((file) => file.name));
    // Proceed only with new files
    this.files.push(...newFiles);
    for (const file of newFiles) {
      if (file.type.split("/")[0] === "image") {
        this.isImage = true;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews.push(e.target.result);
      };
      reader.readAsDataURL(file);
      const path = `imageOnVault/${new Date().getTime()}_${file.name}`;
      const photoRef = this.storage.ref(path);
      const customMetadata = { app: "My AngularFire-powered PWA!" };
      const task = this.storage.upload(path, file, { customMetadata });
      this.percentage = task.percentageChanges();
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            photoRef.getDownloadURL().subscribe((url) => {
              if (url) {
                this.imageUrls.push(url); // Push the URL of the uploaded image into the imageUrls array
              }
            });
          })
        )
        .subscribe((uploadTaskSnapshot) => {
          if (uploadTaskSnapshot) {
            this.updateProgress(
              this.progressBar,
              (uploadTaskSnapshot.bytesTransferred /
                uploadTaskSnapshot.totalBytes) *
                100
            );
          }
        });
    }
  }

  uploadFileToServer(file: File, fileName: string): void {
    const filePath = fileName;
    const fileRef = this.storage.ref(filePath);

    console.log("Upload file to server: ",fileName);

    // Perform file upload
    const task = this.storage.upload(filePath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          // Get download URL after upload completion
          fileRef.getDownloadURL().subscribe((url) => {
            if (url) {
              // Update image URL and name
              this.vaultImage = url;
              this.newImageName = Number(fileName);
              this.loading = false;
            }
          });
        })
      )
      .subscribe((uploadTaskSnapshot) => {
        if (uploadTaskSnapshot) {
          this.updateProgress(
            this.progressBar,
            (uploadTaskSnapshot.bytesTransferred /
              uploadTaskSnapshot.totalBytes) *
              100
          );
        }
      });
  }

  updateProgress(progressBar: IProgressBar, value: number) {
    progressBar.progress = value;
  }

  import() {
    if (this.upload && this.upload.downloadURL) {
      this.saving = true;
      this.upload.storagePath.subscribe((path) => {
        const importFunction = this.fns.httpsCallable("importProductCSV");
        const body = {
          url: path,
          retailerId: this.retailer.id,
        };
        const response$ = importFunction(body);
        response$.subscribe(
          (result) => {
            if (result) {
              // not sure what to do yet
              console.log(result);
            }
            this.saving = false;
            this.modalService.dismissAll();
            this.router.navigate(["landing/retailers"]);
          },
          (error) => {
            this.notify.update(error.message, "danger");
            this.saving = false;
            this.modalService.dismissAll();
            this.router.navigate(["landing/retailers"]);
          }
        );
      });
    }
  }
}
