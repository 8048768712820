import * as actions from './retailer.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { Statement } from '../models/models';

// Main data interface
export interface Option {
    id?: string;
  name: string;
  price: number;
  image?: string;
  imageName?: number;
  category?: string;
  quantity?: number;
}
export interface CalendarOptions {
    saturdayCharge: number;
    sundayCharge: number;
    holidayCharge: number;
}
export interface HolidayOptions {
    name: string;
    enabled: boolean;
}

export interface address {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
}

export interface AdditionalCharges {
    creditCardFee: number;
    achFee: number;
    onAccountFee: number;
}

export interface PlatformFee {
    customer: boolean;
    amount: number;
}

export interface Holiday {
    active: boolean;
    holidayDate: string;
    holidayName: string;
}

export interface Retailer {
  statements?: Statement[];
  invoices?: Statement[];
  id?: string;
  name: string;
  email: string;
  cellPhone: string;
  fax: string;
  platformFee?: PlatformFee;
  productOptions?: [Option];
  serviceType?: [Option];
  serviceExtras?: [Option];
  calendarOptions?: CalendarOptions;
  holidayOptions?: Holiday[];
  logo?: string;
  source?: string;
  client_secret?: string;
  btok?: string;
  ba?: string;
  last4?: string;
  bankName?: string;
  verified?: boolean;
  stripeCustomerId?: string;
  stripeAccount?: string;
  address?: address;
  holidaySettings?: [HolidayOptions];
  additionalCharges?: AdditionalCharges;
  isDeleted?: boolean;
  paymentFrequency: 'daily' | 'monthly';
  disabledDates: string[];
}

// Entity adapter
export const retailerAdapter = createEntityAdapter<Retailer>();
export interface State extends EntityState<Retailer> { }


export const initialState: State = retailerAdapter.getInitialState();

// Reducer

export function retailerReducer(
    state: State = initialState,
    action: actions.RetailerActions) {

    switch (action.type) {

        case actions.ADDED:
            return retailerAdapter.addOne(action.payload, state);

        case actions.MODIFIED:
            return retailerAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state);

        case actions.REMOVED:
            return retailerAdapter.removeOne(action.payload.id, state);

        case actions.DELETE:
            return retailerAdapter.removeOne(action.id, state);

        case actions.UPDATE:
            return retailerAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state);

        default:
            return state;
    }

}

// Create the default selectors

export const getRetailerState = createFeatureSelector<State>('retailer');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = retailerAdapter.getSelectors(getRetailerState);



