import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as actions from '../retailer.actions';
import { Retailer, Holiday, selectAll as selectAllRetailers, State } from '../retailer.reducer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadPageComponent } from 'src/app/uploads/upload-page/upload-page.component';
import { Subscription, Observable, Observer, BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ComponentCanDeactivate } from 'src/app/services/component-can-deactivate';
import { User } from 'src/app/models/user.model';
import Holidays from 'date-holidays';
import { Product, selectAll } from '../../products/products.reducer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map, switchMap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NotifyService } from 'src/app/shared/notify.service';
import { Add, Query, Update } from '../../products/products.actions';
import { Query as RetailerQuery } from '../../retailer/retailer.actions';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
// import { event } from 'firebase-functions/v1/analytics';

interface IProgressBar {
  progress: number;
}

@Component({
  selector: 'app-new-retailer',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewRetailerComponent extends ComponentCanDeactivate implements OnInit, AfterViewInit, OnDestroy {
  @Input() retailerId: string;
  @ViewChild(UploadPageComponent, { static: false }) upload: UploadPageComponent;
  @ViewChild('formDialog', { static: true }) formDialog;
  @Input() options: Product[];
  @Input() isAdmin: boolean;
  @Input() addImage: boolean;
  @Input() showCategory: boolean;
  @Output() changes = new EventEmitter<boolean>();
  @Output() tabClicked = new EventEmitter<number>();

  retailer: Retailer;
  public products: Product[] = [];
  productForm: FormGroup;
  form: FormGroup;
  private subscriptions: Subscription[] = [];
  retailers$: Observable<Retailer[]>;
  minDate;
  holidays;
  holidayList: Holiday[] = [];
  // private productsCollection: AngularFirestoreCollection<Product>;
  columns = [
    'name',
    'price',
    'edit',
    'delete'
  ];

  columnsDef = [
    {heading: 'Category', property: 'category', prefix: ''},
    {heading: 'Name', property: 'name', prefix: ''},
    {heading: 'Price', property: 'price', prefix: '$'}
  ];

  validationMessages = {
    name: [
      { type: 'required', message: 'Retailer Name is required.' }
    ],
    email: [
      { type: 'required', message: 'Retailer Email is required.' }
    ],
    cellPhone: [
      { type: 'required', message: 'Retailer Cell Phone is required.' }
    ],
    fax: [
      { type: 'required', message: 'Retailer Fax Number is required.' },
    ],
    logo: [
      { type: 'required', message: 'Retailer Logo is required.' },
    ],
    platformFee: [
      { type: 'required', message: 'Retailer Platform Fee is required.' },
    ],
    price: [
      { type: 'required', message: 'Price  is required.' }
    ],
    category: [
      { type: 'required', message: 'category  is required.' }
    ],
  };

  // Private data source
  heroes$: Observable<any>;

  // Public Observable for table
  dataSource$: Observable<any[]>;

  minMax$: Observable<any>;

  // Pagination data
  currentPage$ = new BehaviorSubject(1);
  dataOnPage$: Observable<any[]>;

  pageSize = 25;

  editMode = false;
  saving = false;
  edit = false;
  pendingChanges = false;
  user: User;
  vaultImage: any;
  imagePreview: any;
  platformFee: number;
  orginalEdit: Product;
  fileUrl: string;
  newImageName: number;
  oldImageName: number;
  selectedFile: File = null;
  downloadUrl: Observable<string>;
  progressBar: IProgressBar = { progress: 0 };
  // loading: Boolean = false;
  loading: boolean;

  tabNumber: number;
  serviceType: Product[];
  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private modalService: NgbModal,
    public changeDetectorRef: ChangeDetectorRef,
    private fns: AngularFireFunctions,
    private notify: NotifyService,
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {
    super();
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    const h = new Holidays('US');
    this.holidays = h.getHolidays(current.getFullYear());
    this.holidayList = this.holidays.map((holiday) => {
      const date = holiday.date.slice(0, 10);
      return {holidayName: holiday.name, holidayDate: date, active: true}
    })
  };

  toggleDate(date){
    date.active = !date.active;
  }

  ngOnInit() {
    const current = new Date();
    const h = new Holidays('US');
    this.holidays = h.getHolidays(current.getFullYear()).concat(h.getHolidays(current.getFullYear() + 1));

    this.subscriptions.push(
      this.auth.user$.subscribe(user => {
        this.user = user;
        this.loadTable(user);
      })
    );
    if (this.showCategory) {
      this.columns.splice(0, 0, 'category');
    }
    this.createForm();
    this.retailers$ = this.store.select(selectAllRetailers);
    this.store.dispatch(  new actions.Query() );

    this.subscriptions.push(
      this.changes.subscribe(data => {
        if (data) {
          this.serviceType = [...this.options];
        }
      })
    );
  }

  ngAfterViewInit() {
    if (this.retailerId) {
      this.findRetailer(this.retailerId);
    }
    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.findRetailer(params.get('id'));
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  findRetailer(id: string) {
    this.subscriptions.push(
      this.retailers$.subscribe(data => {
        data.forEach((r: Retailer) => {
          if (r.id === id) {
            this.platformFee = r.platformFee.amount * 100;
            this.retailer = r;
            this.edit = true;
            this.retailerId = r.id;
            this.form.get('name').setValue(r.name);
            this.form.get('email').setValue(r.email);
            this.form.get('cellPhone').setValue(r.cellPhone);
            this.form.get('fax').setValue(r.fax);
            this.form.get('platformFee').setValue(r.platformFee.amount * 100);
            this.form.get('platformFeeToCustomer').setValue(r.platformFee.customer);
            this.form.get('creditCardFee').setValue(r.additionalCharges.creditCardFee * 100);
            this.form.get('achFee').setValue(r.additionalCharges.achFee * 100);
            this.form.get('onAccountFee').setValue(r.additionalCharges.onAccountFee * 100);
            this.form.get('paymentFrequency').setValue(r.paymentFrequency);
            if (r.calendarOptions) {
              this.form.get('saturdayFee').setValue(r.calendarOptions.saturdayCharge);
              this.form.get('sundayFee').setValue(r.calendarOptions.sundayCharge);
              this.form.get('holidayFee').setValue(r.calendarOptions.holidayCharge);
            }
            if (r.holidayOptions) {
              this.holidayList = r.holidayOptions;
            }
            if (this.upload) {
              this.upload.downloadURL = Observable.create((observer: Observer<string>) => {
                observer.next(r.logo);
                observer.complete();
              });
            }
            if (!this.products) {
              this.products = [
                {
                  category: 'Example',
                  name: 'Example Vault',
                  price: 1,
                  quantity: 1,
                  image: '',
                  imageName: 1,
                  retailerRef: null,
                  history: [],
                  isDeleted: false,
                  createAt: null,
                  updatedAt: null,
                }
              ];
            }
            if (!this.retailer.serviceType) {
              this.retailer.serviceType = [
                {
                  name: 'Example Type',
                  price: 1
                }
              ];
            }
            if (!this.retailer.serviceExtras) {
              this.retailer.serviceExtras = [
                {
                  name: 'Example Option',
                  price: 1
                }
              ];
            }
            this.changeDetectorRef.detectChanges();
          }
        });
      })
    );
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required ],
      email: ['', Validators.required ],
      cellPhone: ['', Validators.required ],
      fax: ['', Validators.required ],
      paymentFrequency: [''],
      platformFee: [''],
      saturdayFee: [''],
      sundayFee: [''],
      holidayFee: [''],
      creditCardFee: [0],
      achFee: [0],
      onAccountFee: [0],
      platformFeeToCustomer: [false]
    });
  }

  onSubmit() {
    const retailer: Retailer = {
      name: this.form.get('name').value,
      email: this.form.get('email').value,
      cellPhone: this.form.get('cellPhone').value,
      fax: this.form.get('fax').value,
      isDeleted: false,
      paymentFrequency: this.form.get('paymentFrequency').value || 'daily',
      disabledDates: [],
      serviceType: [
        {
          name: 'Example Type',
          price: 1
        }
      ],
      serviceExtras: [
        {
          name: 'Example Option',
          price: 1
        }
      ]
    };

    if (this.retailer && this.retailer.serviceExtras) {
      retailer.serviceExtras = this.retailer.serviceExtras;
    }
    if (this.retailer && this.retailer.serviceType) {
      retailer.serviceType = this.retailer.serviceType;
    }
    retailer.calendarOptions = {
      saturdayCharge: this.form.get('saturdayFee').value || 0,
      sundayCharge: this.form.get('sundayFee').value || 0,
      holidayCharge: this.form.get('holidayFee').value || 0
    };
    retailer.platformFee = {
      customer: this.form.get('platformFeeToCustomer').value,
      amount: this.form.get('platformFee').value / 100
    };
    retailer.additionalCharges = {
      creditCardFee: this.form.get('creditCardFee').value / 100,
      achFee: this.form.get('achFee').value / 100,
      onAccountFee: this.form.get('onAccountFee').value /100
    };
    retailer.holidayOptions = this.holidayList;
    this.form.markAsPristine();
    if (this.upload && this.upload.downloadURL) {
      this.upload.downloadURL.subscribe(url => {
        retailer.logo = url;
        this.save(retailer);
      });
    } else {
      this.save(retailer);
    }

  }

  save(retailer) {
    if (this.edit) {
      this.store.dispatch( new actions.Update(this.retailerId, { ... retailer }, this.user) );
    } else {
      this.store.dispatch( new actions.Add({ ... retailer }, this.user) );
    }
    this.pendingChanges = false;
    if (this.auth.isAdmin(this.user)) {
      this.router.navigate(['landing/retailers']);
    } else {
      this.router.navigate(['landing']);
    }
  }

  onChanges(changes: any) {
    this.pendingChanges = this.pendingChanges || changes;
    this.retailer.serviceType = changes;
    this.changeDetectorRef.detectChanges();
  }

  public canDeactivate(): boolean {
    return !this.form.dirty && !this.pendingChanges;
  }

  loadTable(user: User) {
    this.store.dispatch(new Query(user));
    this.store.select(selectAll).subscribe(products => {
      this.products = Object.values(products).sort((a, b) => {
        if (a.category > b.category) return 1;
        if (a.category < b.category || a.name < b.name) return -1;
        return a.name.localeCompare(b.name);
      });
      this.changeDetectorRef.detectChanges();
    })
  }

  resetFields() {
    this.productForm = this.fb.group({
      category: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required),
      quantity: new FormControl('', Validators.required),
    });
  }

  onTabClick(tabNumber: number) {
    this.tabNumber = tabNumber;
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, () => {
    });
  }

}
