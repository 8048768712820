import * as actions from './updates.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';

export interface Updates {
  id?: string;
  disabledDates?: string[];
  generalAnnouncements?: string[];
}

// Entity adapter
export const updatesAdapter = createEntityAdapter<Updates>();
export interface State extends EntityState<Updates> { }


export const initialState: State = updatesAdapter.getInitialState();

// Reducer

export function updatesReducer(state: State = initialState, action: actions.UpdatesActions) {
  switch (action.type) {
    case actions.ADDED:
      return updatesAdapter.addOne(action.payload, state);
    case actions.MODIFIED:
      return updatesAdapter.updateOne({
          id: action.payload.id,
          changes: action.payload
      }, state);
    case actions.REMOVED:
      return updatesAdapter.removeOne(action.payload.id, state);
    case actions.DELETE:
      return updatesAdapter.removeOne(action.id, state);
    case actions.UPDATE:
      return updatesAdapter.updateOne({
        id: action.id,
        changes: action.changes
      }, state);
    default:
      return state;
  }
}

// Create the default selectors

export const getUpdatesState = createFeatureSelector<State>('updates');

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = updatesAdapter.getSelectors(getUpdatesState);



