import { Action } from '@ngrx/store';
import { Updates } from './updates.reducer';

export const QUERY    = '[updates] query updates';

export const ADDED    = '[updates] added';
export const MODIFIED = '[updates] modified';
export const REMOVED  = '[updates] removed';

export const UPDATE   = '[updates] update';
export const ADD      = '[updates] add';
export const DELETE   = '[updates] delete';
export const SUCCESS  = '[updates] add/update success';

// Initial query
export class Query implements Action {
    readonly type = QUERY;
    constructor() {}
  }

// AngularFire2 StateChanges
export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Updates) {}
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Updates) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Updates) {}
}

// Run a Firestore Update
export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: Partial<Updates>
      ) { }
}

// Run a Firestore Delete
export class Delete implements Action {
    readonly type = DELETE;
    constructor(
        public id: string
      ) { }
}

// Run a Firestore Add
export class Add implements Action {
    readonly type = ADD;
    constructor(
        public updates: Updates
      ) { }
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() {}
}

export type UpdatesActions =
    Query |
    Added |
    Modified |
    Removed |
    Delete |
    Update |
    Success;
