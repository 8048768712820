import { Component, OnInit, Pipe } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/services/auth.service';
import { Product, selectAll as selectAllProducts } from '../products.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { Query as QueryProducts, Update } from '../products.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
  public form: FormGroup;
  public products: Product[];
  private user: User;
  productsByCategory: { category: string, products: any[] }[] = [];
  product$: Observable<Product[]>;
  public validationMessages = {
    product: [
      { type: 'required', message: 'Product must be provided' }
    ],
    quantity: [
      { type: 'required', message: 'Quantity must be provided' }
    ]
  }

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.auth.user$.subscribe(user => {
      if (user.roles.retailer) {
        this.user = user;
        this.store.dispatch(new QueryProducts(user));
        this.store.select(selectAllProducts).subscribe(res => {
        const groupedProducts: {[category: string]: Product[] } = {};
        res.forEach(product => {
          if(groupedProducts[product.category]) {
            groupedProducts[product.category].push(product);
          } else {
            groupedProducts[product.category] = [product];
          }
        });
        this.productsByCategory = Object.entries(groupedProducts)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([category, products]) => ({
          category,
          products: products.sort((a, b) => a.name.localeCompare(b.name))
        }));
      });
      }
    });
  }

  get productArray() {
    return this.form.get('productArray') as FormArray;
  }

  private initProductFields(product: Product) {
    return this.fb.group({
      product: [product, Validators.required],
      quantity: [product?.quantity, Validators.required],
    });
  }

  public addItem(product: Product) {
    this.productArray.push(this.initProductFields(product));
  }

  public removeItem(index) {
    this.productArray.removeAt(index);
  }

  private createForm() {
    this.form = this.fb.group({
      productArray: this.fb.array([this.initProductFields(null)])
    });
  }

  public confirm(content) {
    if(this.form.valid) {
      this.modalService.open(content);
    }
  }

  public onSave() {
    const date = new Date();
    this.productArray.controls.forEach(control => {
      const product = control.get('product').value as Product;
      const quantity = control.get('quantity').value as number;
      const history = product.history;
      history.push({
        quantityAdded: quantity,
        updatedAt: date
      });
      this.store.dispatch(new Update(product.id, {
        history,
        quantity: product.quantity + quantity
      }, this.user));
    });
    this.modalService.dismissAll();
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
