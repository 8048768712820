import { Action } from '@ngrx/store';
import { User } from '../models/user.model';
import { Survey } from './surveys.reducer';

export const QUERY    = '[survey] query surveys';
export const QUERYUSERSURVEYS    = '[survey] query-user surveys';

export const ADDED    = '[survey] added';
export const ADDEDALL    = '[survey] added all';
export const MODIFIED = '[survey] modified';
export const MODIFIEDALL = '[survey] modified all';
export const REMOVED  = '[survey] removed';
export const REMOVEDALL  = '[survey] removed all';

export const UPDATE   = '[survey] update';
export const ADD      = '[survey] add';
export const DELETE   = '[survey] delete';
export const SUCCESS  = '[survey] add/update success';

// Initial query
export class Query implements Action {
    readonly type = QUERY;
    constructor() {}
}

export class QueryUserSurveys implements Action {
    readonly type = QUERYUSERSURVEYS;
    constructor(public user: User) {}
}

// AngularFire2 StateChanges
export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Survey) {}
}

export class AddedALL implements Action {
    readonly type = ADDEDALL;
    constructor(public payload: Survey[]) {}
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Survey) {}
}

export class ModifiedAll implements Action {
    readonly type = MODIFIEDALL;
    constructor(public payload: Survey[]) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Survey) {}
}

export class RemovedAll implements Action {
    readonly type = REMOVEDALL;
    constructor(public payload: Partial<Survey>[]) {}
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() {}
}

// Run a Firestore Update
export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: Partial<Survey>
      ) { }
}

export type SurveyActions =
    Query |
    QueryUserSurveys |
    Added |
    AddedALL |
    Modified |
    ModifiedAll |
    Removed |
    RemovedAll |
    Update |
    Success;
