import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actions, Effect } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ofType } from "redux-observable";
import { from, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import * as actions from "./admin.actions";
import { ADDALL, MODIFIEDALL, REMOVEDALL } from "./admin.actions";
import { Admin } from "./admin.reducer";

@Injectable()
export class AdminEffects {
    constructor(private afs: AngularFirestore, private action$: Actions) {}

    @Effect()
    query$: Observable<Action> = this.action$.pipe(
        ofType(actions.QUERY),
        map((action: actions.Query) => action),
        switchMap(() => {
            return this.afs.collection('admins').stateChanges();
        }),
        map(actions => {
            let changes = [];
            if (actions) {
                const addActions = actions.filter(val => val.type === 'added');
                const removeActions = actions.filter(val => val.type === 'removed');
                const modifyActions = actions.filter(val => val.type === 'modified');
                if (addActions.length > 0) {
                    changes.push({
                        type: ADDALL,
                        payload: addActions.map(item => {
                            const admin = item.payload.doc.data() as Admin;
                            return {
                                id: item.payload.doc.id,
                                ref: item.payload.doc.ref,
                                ...admin
                            }
                        })
                    });
                }
                if (removeActions.length > 0) {
                    changes.push({
                        type: REMOVEDALL,
                        payload: removeActions.map(item => ({
                            id: item.payload.doc.id
                        }))
                    });
                }
                if (modifyActions.length > 0) {
                    changes.push({
                        type: MODIFIEDALL,
                        payload: modifyActions.map(item => {
                            const admin = item.payload.doc.data() as Admin;
                            return {
                                id: item.payload.doc.id,
                                ref: item.payload.doc.ref,
                                ...admin
                            }
                        })
                    });
                }
            }
            return changes;
        }),
        filter(admins => admins.length > 0),
        switchMap(admins => from(admins))
    );
}
