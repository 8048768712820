import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as csvtojson from "csvtojson"; 
import * as actions from "../customer.actions";
import * as fromCustomer from "../customer.reducer";
import { Customer } from "../customer.reducer";
import { AuthService } from "src/app/services/auth.service";
import { Permission, User } from "src/app/models/user.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NotifyService } from "src/app/shared/notify.service";
import { logAction } from "src/app/shared/functions";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { Router, ActivatedRoute } from "@angular/router";
import * as XLSX from "xlsx";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  columns = [
    "name",
    "email",
    "addressLine1",
    "city",
    "state",
    "zip",
    "new-user",
    "statements",
    "edit",
    "delete",
  ];

  columnsDef = [
    { heading: "Name", property: "name" },
    { heading: "Address Line 1", property: "addressLine1" },
    { heading: "Email", property: "email" },
    { heading: "City", property: "city" },
    { heading: "State", property: "state" },
    { heading: "Zip", property: "zip" },
  ];

  // Private data source
  heroes$: Observable<any>;
  user: User;
  searchTerm: string = "";
  // Public Observable for table
  dataSource$: Observable<any[]>;

  minMax$: Observable<any>;

  emailExists$: Observable<boolean>;

  // Pagination data
  currentPage$ = new BehaviorSubject(1);
  dataOnPage$: Observable<any[]>;

  pageSize = 25;
  saving = false;
  form: FormGroup;
  validationMessages = {
    email: [{ type: "required", message: "Email is required." }],
    password: [{ type: "required", message: "Password is required." }],
    displayName: [{ type: "required", message: "User name is required." }],
    permission: [
      {
        type: "required",
        message: "Please select a permission from the dropdown",
      },
    ],
  };

  permissions: Permission[];

  constructor(
    private store: Store<fromCustomer.State>,
    private changeDetectorRef: ChangeDetectorRef,
    public auth: AuthService,
    private fb: FormBuilder,
    private fns: AngularFireFunctions,
    private modalService: NgbModal,
    private notify: NotifyService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  loadingCsv = false;
  csvNotValid = false;
  csvUploaded = false;

  csvData: any[] = [];
  customer: Customer;

  ngOnInit() {
    this.createForm();
    this.heroes$ = this.store.select(fromCustomer.selectAll);
    this.dataSource$ = this.heroes$.pipe(map((v) => Object.values(v)));
    const data$ = combineLatest([this.auth.user$, this.auth.permissions$]);
    this.subscriptions.push(
      data$.subscribe(([user, permissions]) => {
        this.user = user;
        this.permissions = permissions;
        this.store.dispatch(new actions.Query(user));
        if (this.auth.isRetailer(user)) {
          this.changeDetectorRef.detectChanges();
        }
      })
    );
    // Sliced data for pagination
    this.dataOnPage$ = this.currentPage$.pipe(
      switchMap(() => this.heroes$),
      map((v) => {
        return Object.values(v)
          .sort((a: Customer, b: Customer) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .slice();
      }),
      map((v) => {
        const idx = (this.currentPage$.value - 1) * this.pageSize;
        return v.slice(idx, idx + this.pageSize);
      })
    );
  }

  createForm() {
    this.form = this.fb.group({
      email: ["", Validators.required],
      displayName: ["", Validators.required],
      password: ["", Validators.required],
      permission: ["", Validators.required],
    });
  }

  resetFields() {
    this.createForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  openBulkUploadModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-customer-bulk-upload",
    });
  }

  downloadTemplate() {
    const templateRef = this.storage.ref("customer_template.xlsx");
    templateRef.getDownloadURL().subscribe((url) => {
      window.open(url);
    });
  }

  onExcelFileSelected(event: any) {
    if (!event || !event.addedFiles || event.addedFiles.length === 0) {
      return; // Exit if event or addedFiles is undefined or empty
    }

    this.loadingCsv = true;
    this.csvNotValid = true;

    if (event.addedFiles.length > 1) {
      alert("You can only upload one file at a time.");
      this.loadingCsv = false;
      return;
    }

    const file = event.addedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonObj = XLSX.utils.sheet_to_json(worksheet);

        // Validation logic here
        if (this.validateCsvData(jsonObj)) {
          this.csvNotValid = false; // xlsx is valid, enable upload button
          this.csvUploaded = true;
          this.csvData = jsonObj;
        } else {
          this.csvNotValid = true; // xlsx is invalid, keep upload button disabled
        }
        this.loadingCsv = false;
      };
      reader.readAsArrayBuffer(file);
    } else {
      this.loadingCsv = false;
    }
  }

  validateCsvData(jsonObj: any[]): boolean {
    // Check if every row of the xlsx has the required fields and valid data
    return jsonObj.every((row) => {
      const hasAllFields =
        row.name &&
        row.email &&
        row.address &&
        row.city &&
        row.state &&
        row.zip;

      return hasAllFields;
    });
  }

  addBulkCustomersToDb() {
    this.onAddBulkCustomer(this.csvData);
  }

  async onAddBulkCustomer(csvData: any[]) {
    const addCustomerPromises = csvData.map((row) => this.processCustomer(row));

    await Promise.all(addCustomerPromises);
    this.onDismissModal();
  }

  async processCustomer(row: any): Promise<void> {
    const customer: Customer = {
      name: row.name,
      addressLine1: row.address,
      addressLine2: "",
      city: row.city,
      state: row.state,
      zip: row.zip,
      email: row.email,
      hidePrice: false,
      isDeleted: false,
      retailerRef: this.auth.retailerRef,
      paymentMethod: "onAccount",
      surveySettings: {
        text: true,
        email: false,
        disabled: false,
      },
    };

    // Additional properties based on xlsx data
    if (row.hidePrice) {
      customer.hidePrice = row.hidePrice === "true";
    }

    if (row.discount) {
      customer.discount = parseFloat(row.discount);
    }

    if (row.salesTax) {
      customer.salesTax = parseFloat(row.salesTax);
    }

    // Check if the email exists before adding the customer
    const emailExists = await this.checkIfEmailExists(
      customer.email
    ).toPromise();

    if (!emailExists) {
      this.store.dispatch(new actions.Add(customer, this.user));
    }
  }

  // Updated to return an Observable directly
  checkIfEmailExists(email: string): Observable<boolean> {
    return this.dataOnPage$.pipe(
      map((data) => data.some((item) => item.email === email)),
      take(1)
    );
  }

  onDismissModal() {
    this.modalService.dismissAll();
    this.resetVariables();
  }

  resetVariables() {
    this.loadingCsv = false;
    this.csvNotValid = false;
    this.csvUploaded = false;
    this.csvData = [];
  }

  get permission(): Permission {
    return this.form.get("permission").value;
  }

  public delete(customer: Customer) {
    this.store.dispatch(new actions.Delete(customer.id, this.user));
    this.modalService.dismissAll();
  }

  async addUser(customer: Customer) {
    this.saving = true;
    this.changeDetectorRef.detectChanges();
    const register = this.fns.httpsCallable("register");
    const body: any = {
      emails: this.form.get("email").value,
      roles: {
        admin: false,
        customer: true,
        retailer: false,
      },
      password: this.form.get("password").value,
      retailerId: this.user.retailerRef.id,
      customerId: customer.id,
      permissionId: this.permission.id,
      user: this.form.get("displayName").value,
    };
    const response$ = register(body);
    response$.subscribe(
      (result) => {
        if (result) {
          this.resetFields();
          this.modalService.dismissAll();
        }
        this.saving = false;
        logAction(
          {
            action: "User added",
            user: this.user,
            customerId: customer.id,
          },
          this.afs
        );
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.modalService.dismissAll();
        this.notify.update(error.message, "danger");
        this.saving = false;
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        () => {},
        () => {}
      );
  }

  applySearch() {
    this.dataOnPage$ = this.heroes$.pipe(
      map((data) => {
        const filteredData = Object.values(data).filter((item) => {
          const values = Object.values(item);
          return values.some(
            (value) =>
              value &&
              typeof value === "string" &&
              value.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        });
        return filteredData;
      })
    );
  }
}
