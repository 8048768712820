import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as actions from "../customer.actions";
import * as fromCustomer from "../customer.reducer";
import { Customer } from "../customer.reducer";

import {
  FormBuilder,
  FormGroup,
  Validators,
  EmailValidator,
  FormControl,
} from "@angular/forms";
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { StripePaymentChoiceComponent } from "src/app/shared/stripe-payment-choice/stripe-payment-choice.component";
import { FormCanDeactivate } from "src/app/shared/form-can-deactivate";
import { Subscription, Observable } from "rxjs";
import { User } from "src/app/models/user.model";
import { CustomDate } from "../../../../functions/src/model/index";

@Component({
  selector: "app-new",
  templateUrl: "./new.component.html",
  styleUrls: ["./new.component.scss"],
})
export class NewComponent
  extends FormCanDeactivate
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(StripePaymentChoiceComponent, { static: true })
  payment: StripePaymentChoiceComponent;

  form: FormGroup;
  private subscriptions: Subscription[] = [];
  user: User;
  customers$: Observable<Customer[]>;
  customer: Customer;
  edit = false;
  loading = false;
  confirmation;

  validationMessages = {
    name: [{ type: "required", message: "Name is required." }],
    addressLine1: [
      { type: "required", message: "Address Line 1 is required." },
    ],
    addressLine2: [
      { type: "required", message: "Address Line 2 is required." },
    ],
    city: [{ type: "required", message: "City is required." }],
    state: [{ type: "required", message: "State is required." }],
    zip: [{ type: "required", message: "Zip is required." }],
    email: [
      {
        type: "required",
        message: "Email is required.",
        format: "email",
        formatMessage: "Please input a valid email",
      },
    ],
    discount: [{ type: "required", message: "Discount is required." }],
    // salesTax: [{ type: "required", message: "Sales Tax is required." }],
  };

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromCustomer.State>,
    private firestore: AngularFirestore,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.customers$ = this.store.select(fromCustomer.selectAll);
    this.subscriptions.push(
      this.auth.user$.subscribe((user) => {
        this.user = user;
        this.store.dispatch(new actions.Query(user));
      })
    );
    if (this.auth.customer$) {
      this.subscriptions.push(
        this.auth.customer$.subscribe((customer) => {
          this.customer = customer;
          console.log("xxxxxxxxxxxxxxxxxxxx", this.customer);
        })
      );
    }
    this.createForm();
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.route.paramMap.subscribe((params) => {
        this.findCustomer(params.get("id"));
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  paymentMethodUpdated({ paymentMethod }) {
    this.store.dispatch(
      new actions.Update(this.customer.id, { paymentMethod }, this.user)
    );
  }

  findCustomer(id: string) {
    this.subscriptions.push(
      this.customers$.subscribe((data) => {
        data.forEach((c: Customer) => {
          if (c.id === id) {
            this.customer = c;
            this.edit = true;
            this.form.get("name").setValue(c.name);
            this.form.get("addressLine1").setValue(c.addressLine1);
            this.form.get("addressLine2").setValue(c.addressLine2);
            this.form.get("city").setValue(c.city);
            this.form.get("state").setValue(c.state);
            this.form.get("zip").setValue(c.zip);
            this.form.get("email").setValue(c.email);
            this.form.get("discount").setValue(c.discount);
            this.form.get("salesTax").setValue(0);
            // this.form.get("salesTax").setValue(c.salesTax);
            this.form.get("hidePrice").setValue(c.hidePrice);
            this.changeDetectorRef.detectChanges();
          }
        });
      })
    );
  }

  tabChanged(event) {}

  createForm() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      city: ["", Validators.required],
      state: ["", Validators.required],
      zip: ["", Validators.required],
      email: ["", Validators.required],
      discount: [""],
      salesTax: [""],
      hidePrice: [""],
      surveyOption: [""],
    });
  }

  resetFields() {
    this.createForm();
  }

  // async onSubmit(value) {
  //   this.form.markAsPristine();

  //   const customer: Customer = {
  //     name: this.form.get("name").value,
  //     addressLine1: this.form.get("addressLine1").value,
  //     addressLine2: this.form.get("addressLine2").value,
  //     city: this.form.get("city").value,
  //     state: this.form.get("state").value,
  //     zip: this.form.get("zip").value,
  //     email: this.form.get("email").value,
  //     hidePrice: this.form.get("hidePrice").value,
  //     isDeleted: false,
  //     retailerRef: this.auth.retailerRef,
  //     // Conditionally include paymentMethod based on the value of this.edit
  //     ...(this.edit ? {} : { paymentMethod: "onAccount" }),
  //     surveySettings: {
  //       text: this.form.get("surveyOption").value === "text",
  //       email: this.form.get("surveyOption").value === "email",
  //       disabled: this.form.get("surveyOption").value === "disabled",
  //     },
  //   };

  //   if (!this.customer) {
  //     customer.surveySettings.text = true;
  //   }

  //   if (this.form.get("discount").value) {
  //     customer.discount = this.form.get("discount").value;
  //   }
  //   if (this.form.get("salesTax").value) {
  //     customer.salesTax = this.form.get("salesTax").value;
  //   }

  //   if (this.edit) {
  //     if (this.payment) {
  //       this.payment.getSource().then((source) => {
  //         if (source && source.bank_account && source.bank_account.id) {
  //           customer.ba = source.bank_account.id;
  //           customer.btok = source.id;
  //           customer.last4 = source.bank_account.last4;
  //           customer.bankName = source.bank_account.bank_name;
  //           customer.verified = false;
  //         } else if (source && source.id) {
  //           customer.source = source.id;
  //           customer.client_secret = source.client_secret;
  //         }
  //       });
  //     }
  //     console.log(customer);
  //     this.store.dispatch(
  //       new actions.Update(this.customer.id, { ...customer }, this.user)
  //     );
  //     this.router.navigate([".."], { relativeTo: this.route });
  //   } else {
  //     this.store.dispatch(new actions.Add({ ...customer }, this.user));
  //     this.router.navigate([".."], { relativeTo: this.route });
  //   }
  // }

  async onSubmit(value) {
    this.form.markAsPristine();

    const customer: Customer ={
      name: this.form.get("name").value,
      addressLine1: this.form.get("addressLine1").value,
      addressLine2: this.form.get("addressLine2").value,
      city: this.form.get("city").value,
      state: this.form.get("state").value,
      zip: this.form.get("zip").value,
      email: this.form.get("email").value,
      hidePrice: this.form.get("hidePrice").value,
      isDeleted: false,
      retailerRef: this.auth.retailerRef,
      // Conditionally include paymentMethod based on the value of this.edit
      ...(this.edit ? {} : { paymentMethod: "onAccount" }),
      surveySettings: {
        text: this.form.get("surveyOption").value === "text",
        email: this.form.get("surveyOption").value === "email",
        disabled: this.form.get("surveyOption").value === "disabled",
      },
    };

    if (!this.customer) {
      customer.surveySettings.text = true;
    }

    if (this.form.get("discount").value) {
      customer.discount = this.form.get("discount").value;
    }
    if (this.form.get("salesTax").value) {
      customer.salesTax = this.form.get("salesTax").value;
    }

    if (this.edit) {
      if (this.payment) {
        const source = await this.payment.getSource();
        if (source && source.bank_account && source.bank_account.id) {
          customer.ba = source.bank_account.id;
          customer.btok = source.id;
          customer.last4 = source.bank_account.last4;
          customer.bankName = source.bank_account.bank_name;
          customer.verified = false;
        } else if (source && source.id) {
          customer.source = source.id;
          customer.client_secret = source.client_secret;
        }
      }

      await this.firestore.collection('customers').doc(this.customer.id).update(customer);
    } else {
      await this.firestore.collection('customers').add(customer);
    }

    // this.router.navigate([".."], { relativeTo: this.route });
    this.router.navigate([".."], { relativeTo: this.route }).then(() => {
      // Reload the page after navigation
      window.location.reload();
    });
  }

  updateCustomer(customerData) {
    this.store.dispatch(
      new actions.Update(this.customer.id, { ...customerData }, this.user)
    );
    this.router.navigate([".."], { relativeTo: this.route });
  }

  addCustomer(customerData) {
    this.store.dispatch(new actions.Add({ ...customerData }, this.user));
    this.router.navigate([".."], { relativeTo: this.route });
  }

  async on1Submit(value) {
    const customer: Customer = {
      name: this.form.get("name").value,
      addressLine1: this.form.get("addressLine1").value,
      addressLine2: this.form.get("addressLine2").value,
      city: this.form.get("city").value,
      state: this.form.get("state").value,
      zip: this.form.get("zip").value,
      email: this.form.get("email").value,
      hidePrice: this.form.get("hidePrice").value,
      isDeleted: false,
      retailerRef: this.auth.retailerRef,
      // Conditionally include paymentMethod based on the value of this.edit
      ...(this.edit ? {} : { paymentMethod: "onAccount" }),
      surveySettings: {
        text: this.form.get("surveyOption").value === "text",
        email: this.form.get("surveyOption").value === "email",
        disabled: this.form.get("surveyOption").value === "disabled",
      },
    };

    if (!this.customer) {
      customer.surveySettings.text = true;
    }

    if (this.form.get("discount").value) {
      customer.discount = this.form.get("discount").value;
    }
    if (this.form.get("salesTax").value) {
      customer.salesTax = this.form.get("salesTax").value;
    }

    if (this.form.valid) {
      this.form.markAsPristine();
      if (this.payment && this.edit) {
        this.payment.getSource().then((source) => {
          if (source && source.bank_account && source.bank_account.id) {
            customer.ba = source.bank_account.id;
            customer.btok = source.id;
            customer.last4 = source.bank_account.last4;
            customer.bankName = source.bank_account.bank_name;
            customer.verified = false;
          } else if (source && source.id) {
            customer.source = source.id;
            customer.client_secret = source.client_secret;
          }

          // this.store.dispatch( new actions.Update(this.customer.id, { ... customer }, this.user) );
          // this.router.navigate(['..'], { relativeTo: this.route });
        });
      } else {
        this.store.dispatch(new actions.Add({ ...customer }, this.user));
        this.router.navigate([".."], { relativeTo: this.route });
      }
    }
  }
}
