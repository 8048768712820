import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { RetailerListComponent } from './list/list.component';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { retailerReducer } from './retailer.reducer';
import { RetailerEffects } from './retailer.effects';
import { TableComponent } from './table/table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewRetailerComponent } from './new/new.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { RetailerRoutingModule } from './retailer.routing.module';
import { UploadsModule } from '../uploads/uploads.module';
import { OptionListComponent } from './option-list/option-list.component'
import { StatementsModule } from '../statements/statements.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table'; // Import MatTableModule
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    RetailerRoutingModule,
    StoreModule.forFeature('retailer', retailerReducer),
    EffectsModule.forFeature([RetailerEffects]),
    CdkTableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDialogModule,
    UploadsModule,
    StatementsModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatProgressSpinnerModule,
    NgxDropzoneModule,
    MatSnackBarModule
  ],
  exports: [
    RetailerListComponent,
    NewRetailerComponent
  ],
  declarations: [
    RetailerListComponent,
    TableComponent,
    NewRetailerComponent,
    OptionListComponent],
})
export class RetailerModule { }
