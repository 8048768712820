
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { CustomerListComponent } from '../customer/customer-list/customer-list.component';
import { NewComponent } from './new/new.component';

const routes: Routes = [
  { path: 'landing/customers',
    component: CustomerListComponent,
    children: [
      { path: 'odd', component: NewComponent, canActivate: [AuthGuard] }
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
