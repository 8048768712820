import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';

import {
  AngularFirestore
} from '@angular/fire/firestore';

import { switchMap, mergeMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { logAction } from '../shared/functions';
import { Add, ADD, Query, QUERY, Success, Update, UPDATE } from './products.actions';
import { Product } from './products.reducer';
import moment from 'moment';

@Injectable()
export class ProductEffects {

  constructor(private actions$: Actions, private afs: AngularFirestore) { }

  query$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QUERY),
      map((action: Query) => action),
      switchMap(action => {
        return this.afs.collection<Product>('products', ref =>  {
          return ref
            .where('retailerRef', '==', action.user.retailerRef)
            .where('isDeleted', '==', false)
        }).stateChanges();
      }),
      mergeMap(actions =>  actions),
      map(action => {
        const updatedAt = action.payload.doc.data().updatedAt as unknown as firebase.default.firestore.Timestamp;
        return {
          type: `[product] ${action.type}`,
          payload: {
            id: action.payload.doc.id,
            displayDate: moment(updatedAt.seconds * 1000).format('MM/DD/YYYY'),
            displayTime: moment(updatedAt.seconds * 1000).format('hh:mm A'),
            ...action.payload.doc.data()
          }
        };
      })
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UPDATE),
      map((action: Update) => action),
      switchMap(data => {
        const ref = this.afs.doc<Product>(`products/${data.id}`);
        logAction({
          action: 'Product updated',
          user: data.user,
          productId: data.id
        }, this.afs);
        delete data.changes.id;
        return from(ref.update(data.changes));
      }),
      map(() => new Success())
    );
  });

  add$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ADD),
      map((action: Add) => action),
      switchMap(async (data) => {
        const col = this.afs.collection<Product>('products');
        delete data.product.id;
        return from(
          col.add(data.product).then(ref => {
            logAction({
              action: 'Product added',
              user: data.user,
              retailerId: ref.id
            }, this.afs);
          })
        );
      }),
      map(() => new Success())
    );
  });
}
