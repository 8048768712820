import { Action } from '@ngrx/store';
import { User } from '../models/user.model';
import { Retailer } from './retailer.reducer';

export const QUERY    = '[retailer] query retailers';
export const QUERYALL = '[retailer] get all retailers';

export const ADDED    = '[retailer] added';
export const MODIFIED = '[retailer] modified';
export const REMOVED  = '[retailer] removed';

export const UPDATE   = '[retailer] update';
export const ADD      = '[retailer] add';
export const DELETE   = '[retailer] delete';
export const SUCCESS  = '[retailer] add/update success';

// Initial query
export class Query implements Action {
    readonly type = QUERY;
    constructor() {}
  }

// Query all retailers
export class QueryAll implements Action {
    readonly type = QUERYALL;
    constructor() {}
}

// AngularFire2 StateChanges
export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Retailer) {}
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Retailer) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Retailer) {}
}

// Run a Firestore Update
export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: Partial<Retailer>,
        public user: User
      ) { }
}

// Run a Firestore Delete
export class Delete implements Action {
    readonly type = DELETE;
    constructor(
        public id: string,
        public user: User
      ) { }
}

// Run a Firestore Add
export class Add implements Action {
    readonly type = ADD;
    constructor(
        public retailer: Retailer,
        public user: User
      ) { }
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() {}
}

export type RetailerActions =
    Query |
    QueryAll |
    Added |
    Modified |
    Removed |
    Delete |
    Update |
    Success;
