import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  accepted = false;

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

  accept() {
    this.auth.user.acceptedTerms = true;
    this.auth.updateUserData(this.auth.user);
  }

}
