import { AngularFirestore } from '@angular/fire/firestore'
import firebase from 'firebase'
import { AppCustomerActions, AppOrderActions, AppPaymentActions, AppProductActions, AppRetailerActions } from '../models/models'
import { User } from '../models/user.model'
import { Order } from '../order/order.reducer'
import { Retailer } from '../retailer/retailer.reducer'


export function logAction(data: {
  action: AppOrderActions | AppCustomerActions | AppRetailerActions | AppPaymentActions | AppProductActions,
  user: User,
  orderId?: string,
  customerId?: string,
  retailerId?: string,
  productId?: string
}, afs: AngularFirestore) {
  afs.collection('logs').add({
    action: data.action,
    user: data.user,
    timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
    orderRef: afs.firestore.doc(`orders/${data.orderId}`),
    customerRef: afs.firestore.doc(`customers/${data.customerId}`),
    retailerRef: afs.firestore.doc(`retailers/${data.retailerId}`),
    productRef: afs.firestore.doc(`products/${data.productId}`)
  });
}

export function calculateOrderTotal(order: Order) {
  let total = 0;
  if (order.items) {
    order.items.forEach(item => total += +item.price * item.qty);
  } else {
    total = +order.productOptions.price;
    if (order.serviceType) {
      total += +order.serviceType.price;
    }
    if (order.serviceExtras) {
      order.serviceExtras.forEach(extra => total += +extra.price);
    }
  }
  if (order.extraCharges) {
    order.extraCharges.forEach(extra => {
      if (extra.name !== 'Platform Fee') {
        total += +extra.price;
      } else if (order.applyPlatformFee) {
        total += +extra.price;
      }
    });
  }
  if (order.salesTax) {
    total += +order.salesTax;
  }
  return total;
}

export function calculateOngoingOrderTotal(order: Order) {
  let total = 0;
  if (order.items) {
    order.items.forEach(item => total += +item.price * item.deliveredQuantity);
  } else {
    total = +order.productOptions.price;
    if (order.serviceType) {
      total += +order.serviceType.price;
    }
    if (order.serviceExtras) {
      order.serviceExtras.forEach(extra => total += +extra.price);
    }
  }
  if (order.extraCharges) {
    order.extraCharges.forEach(extra => {
      if (extra.name !== 'Platform Fee') {
        total += +extra.price;
      } else if (order.applyPlatformFee) {
        total += +extra.price;
      }
    });
  }
  return total;
}

export function calculateDiffTotals(order: Order) {
  const orderTotal = calculateOrderTotal(order);
  const ongoingOrderTotal = calculateOngoingOrderTotal(order);
  return orderTotal - ongoingOrderTotal;
}


export function printPage() {
  const printAble = document.getElementById('print-able');
  const windowPrint = window.open('', '', 'left=0, top=0, width=900, toolbar=0, scrollbars=0, status=0');
  windowPrint.document.write(printAble.innerHTML);
  windowPrint.document.close();
  windowPrint.focus();
  windowPrint.print();
  windowPrint.close();
}
