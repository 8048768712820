
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { RetailerListComponent } from './list/list.component';
import { NewRetailerComponent } from './new/new.component';

const routes: Routes = [
  { path: 'landing/retailers',
    component: RetailerListComponent,
    children: [
      {
        path: 'new',
        component: NewRetailerComponent,
        canActivate: [AuthGuard],
        data: {
          permissionIds: ['READ_WRITE']
        }
      }
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RetailerRoutingModule { }
