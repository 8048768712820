import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Input() saving;
  @ViewChild('uploadDialog', { static: true }) uploadDialog;
  constructor(
    private modalService: NgbModal,
    ) {}
  import() {
    this.submit.emit();
  }
  public open() {
    this.modalService.open(this.uploadDialog, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }
}
